import RAFGridColumn from "../../../../RAFComponents/Grid/RAFGridColumn";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  isNotEmptyArray,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import {
  RAFShiftActionStatus,
  SFColumnType,
} from "../../../../constants/Common/Constants";
import { RAFDefaultGridColumnProps } from "../../../Common/List/IndexHelper";
import {
  getPayrollInvoiceTransactionByClientUID,
  getServiceTransactionDisplayNameFromStorage,
} from "../ServiceTransactionHelper";
import { ServiceTransactionRow } from "../ServiceTransactionRow";
import {
  shiftDateTitleTemplate,
  shiftDurationTemplate,
  shiftPayRollDurationTemplate,
  shiftSeriveStatusTemplate,
} from "../../../CareESIO/CareShiftLog/CareBillingTimesheet/BillingTimesheetTemplateHelper";

export enum TimeSheetSummaryModuleName {
  InvoiceTransaction = "invoice_transaction", //billing
  PayrollTransaction = "payroll_transaction", // timesheet
}

export class TimeSheetSummaryDetailsArray {
  url: string;
  title: string;
  count: number;
  themeName: string;
  careRecipientUID: string;
  shiftUID?: string;
  moduleName:
    | TimeSheetSummaryModuleName.InvoiceTransaction
    | TimeSheetSummaryModuleName.PayrollTransaction;
  fieldProperty: keyof ServiceTransactionRow;
  fieldStatus: string;
}

export const getDefaultTimeSheetSummaryDetailsArray = (
  careRecipientUID: string
) => {
  const defaultTimeSheetSummaryDetailsArray: TimeSheetSummaryDetailsArray[] = [
    {
      url: "InvoiceTransaction/ListByClient",
      title: "Awaiting Time Approval",
      count: 0,
      themeName: "info",
      careRecipientUID: careRecipientUID,
      moduleName: TimeSheetSummaryModuleName.InvoiceTransaction,
      fieldProperty: propertyOf<ServiceTransactionRow>("Status"),
      fieldStatus: RAFShiftActionStatus.Completed,
    },
    {
      url: "PayrollTransaction/ListByClient",
      title: "Ready to Invoice",
      count: 0,
      themeName: "success",
      careRecipientUID: careRecipientUID,
      moduleName: TimeSheetSummaryModuleName.PayrollTransaction,
      fieldProperty: propertyOf<ServiceTransactionRow>("BillingStatus"),
      fieldStatus: RAFShiftActionStatus.Ready,
    },
    {
      url: "PayrollTransaction/ListByClient",
      title: "Cancelled",
      count: 0,
      themeName: "warning",
      careRecipientUID: careRecipientUID,
      moduleName: TimeSheetSummaryModuleName.PayrollTransaction,
      fieldProperty: propertyOf<ServiceTransactionRow>("Status"),
      fieldStatus: "Cancelled",
    },
  ];

  return defaultTimeSheetSummaryDetailsArray;
};

export const getTimeSheetSummaryDetailsArray = (clientUID: string) => {
  return new Promise<TimeSheetSummaryDetailsArray[]>(async (resolve) => {
    const timeSheetSummaryDetailsArray =
      getDefaultTimeSheetSummaryDetailsArray(clientUID);

    for (const timeSheetSummaryDetails of timeSheetSummaryDetailsArray) {
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      let filter: RAFCustomFilter = {};
      let filterValue: string[] = [];
      filterValue.push(timeSheetSummaryDetails.fieldStatus);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterValue;
      filter.Field = timeSheetSummaryDetails.fieldProperty;
      customFilter.Rules.push(filter);

      const allRelatedServiceTransaction =
        await getPayrollInvoiceTransactionByClientUID(
          clientUID,
          timeSheetSummaryDetails.moduleName,
          customFilter
        );
      timeSheetSummaryDetails.count = isNotEmptyArray(
        allRelatedServiceTransaction
      )
        ? allRelatedServiceTransaction.length
        : 0;
    }

    resolve(timeSheetSummaryDetailsArray);
  });
};

export const getDefaultGridColumnsInvoicePayroll = (
  moduleName:
    | TimeSheetSummaryModuleName.InvoiceTransaction
    | TimeSheetSummaryModuleName.PayrollTransaction
) => {
  let defaultGridColumns: RAFDefaultGridColumnProps[];

  if (moduleName === TimeSheetSummaryModuleName.PayrollTransaction) {
    defaultGridColumns = [
      {
        field: "StartDate",
        headerText: "Start Date",
        width: "100px",
        minWidth: "100px",
        maxWidth: "100px",
        type: SFColumnType.date,
      },
      {
        field: "EndDate",
        headerText: "End Date",
        width: "100px",
        minWidth: "100px",
        maxWidth: "100px",
        type: SFColumnType.date,
      },
      {
        field: "Duration",
        headerText: "Duration",
        width: "100px",
        minWidth: "100px",
        maxWidth: "100px",
        template: shiftPayRollDurationTemplate,
      },
      {
        field: "PayLoading",
        headerText: "PayLoading",
        width: "100px",
        minWidth: "100px",
        maxWidth: "100px",
      },
      {
        field: "PayLoadingRate",
        headerText: "PayLoadingRate",
        width: "100px",
        minWidth: "100px",
        maxWidth: "100px",
      },
      {
        field: "Status",
        headerText: "Status",
        width: "100px",
        minWidth: "100px",
        maxWidth: "100px",
        template: shiftSeriveStatusTemplate,
      },
    ];
  } else {
    defaultGridColumns = [
      {
        field: "StartDate",
        headerText: `${getServiceTransactionDisplayNameFromStorage()} Time`,
        width: "100px",
        minWidth: "100px",
        maxWidth: "100px",
        type: SFColumnType.string,
        template: shiftDateTitleTemplate,
      },
      {
        field: "Duration",
        headerText: "Duration",
        width: "100px",
        minWidth: "100px",
        maxWidth: "100px",
        template: shiftDurationTemplate,
      },
      {
        field: "InvoiceAmount",
        headerText: "Invoice Amount",
        width: "100px",
        minWidth: "100px",
        maxWidth: "100px",
      },
      {
        field: "Rate",
        headerText: "Rate",
        width: "100px",
        minWidth: "100px",
        maxWidth: "100px",
      },
      {
        field: "RateType",
        headerText: "Rate Type",
        width: "100px",
        minWidth: "100px",
        maxWidth: "100px",
      },
      {
        field: "ServiceContractItem",
        headerText: "Service Contract Item",
        width: "100px",
        minWidth: "100px",
        maxWidth: "100px",
      },
      {
        field: "BillingStatus",
        headerText: "Billing Status",
        width: "100px",
        minWidth: "100px",
        maxWidth: "100px",
        template: shiftSeriveStatusTemplate,
      },
      {
        field: "Status",
        headerText: "Status",
        width: "100px",
        minWidth: "100px",
        maxWidth: "100px",
        template: shiftSeriveStatusTemplate,
      },
    ];
  }

  return defaultGridColumns.map((item, index) => {
    return (
      <RAFGridColumn
        key={index}
        field={item.field}
        headerText={item.headerText}
        type={item.type}
        template={item.template}
      />
    );
  });
};
