import React, { Fragment, PropsWithChildren, Reducer, useEffect, useReducer } from "react";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFCollapseWithPlusIcon from "../../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import RAFEmptyState from "../../../../RAFComponents/Navigation/RAFEmptyState";
import { IsNotNullOrWhiteSpace, isNotEmptyArray, isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import { getServiceTransactionDisplayNameFromStorage } from "../ServiceTransactionHelper";
import { ServiceTransactionRow } from "../ServiceTransactionRow";
import ServiceTransactionTemplateCardContent from "./ServiceTransactionTemplateCardContent";
import { getServiceTransactionListTemplate } from "./ServiceTransactionTemplateHelper";

interface IProps {
    relatedTo: string;
    relatedToUID: string;
    relatedToType: string;
    transactionTemplateCardUIMode?: 'Card' | 'default';
    showEmptyMessage?: boolean;
}

interface IState {
    isLoading: boolean;
    serviceTransactionListTemplate: ServiceTransactionRow[];
}

function ServiceTransactionTemplateList({
    showEmptyMessage = true,
    ...props }: PropsWithChildren<IProps>) {

    const serviceTransactionDisplayName = getServiceTransactionDisplayNameFromStorage();
    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isLoading: true,
            serviceTransactionListTemplate: [],
        }
    );

    useEffect(() => {
        refresh();
    }, [props.relatedToUID]);

    const refresh = async () => {
        setState({ isLoading: true });
        const [serviceTransactionListTemplate] = await Promise.all([
            getServiceTransactionListTemplate(props.relatedToUID),
        ]);
        setState({ serviceTransactionListTemplate, isLoading: false });
    };

    const getServiceTransactionTemplateList = () => {
        if (isNotEmptyArray(state.serviceTransactionListTemplate)) {
            return (
                <CustomCardWidget removeContentPadding>
                    <RAFCollapseWithPlusIcon
                        toggleArrowIcon="Arrow"
                        title={`${serviceTransactionDisplayName} Template`}
                        collapsePanelHeaderClass={"collapsePanel__header__fixedHeight__lg"}
                        // collapsePanelHeaderClass={"px-0"}
                        allowFullRowClick
                        badge={{
                            count: IsNotNullOrWhiteSpace(state.serviceTransactionListTemplate) && IsNotNullOrWhiteSpace(state.serviceTransactionListTemplate.length) ? state.serviceTransactionListTemplate.length : 0,
                            show: true,
                            className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
                        }}
                        isCollapsed={true}
                        showSeparator={true}
                    >
                        <div className="row g-0 gy-2">
                            {state.serviceTransactionListTemplate.map((item) => {
                                return (
                                    <Fragment key={item.UID}>
                                        {getServiceTransactionTemplateCardContent(item)}
                                    </Fragment>
                                );
                            })}
                        </div>
                    </RAFCollapseWithPlusIcon>
                </CustomCardWidget>
            );
        } else if (showEmptyMessage) {
            return (
                <div className="col-12">
                    <div className="custom__card__content p-0">
                        <div className="row g-3">
                            <div className="d-flex align-items-center justify-content-center flex-column">
                                <div className="mt-3">
                                    <RAFEmptyState
                                        title={`${serviceTransactionDisplayName} Template`}
                                        body={`No ${serviceTransactionDisplayName} Template available at this time.`}
                                        displayStyle="TextOnly"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return;
        }
    };

    const getServiceTransactionTemplateCardContent = (serviceTransactionTemplateRow: ServiceTransactionRow) => {
        if (isNotNullAndUndefined(serviceTransactionTemplateRow)) {
            return (
                <ServiceTransactionTemplateCardContent
                    key={serviceTransactionTemplateRow.UID}
                    serviceTransactionTemplateRow={serviceTransactionTemplateRow}
                    onUpdateTemplate={refresh}
                    uiMode={props.transactionTemplateCardUIMode}
                />
            );
        } else {
            return null;
        }
    };


    if (state.isLoading === false) {
        return (
            getServiceTransactionTemplateList()
        );
    } else {
        return (
            <div className="container-fluid px-0">
                <ACLoadingPanel loadingText="Preparing Data..." />
            </div>
        );
    }
}

export default React.memo(ServiceTransactionTemplateList);