import moment from "moment";
import * as R from "ramda";
import { msalInstance } from "../../..";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { getSessionStorage } from "../../../RAFComponents/helpers/AppHelper";
import { getLookUpItems } from "../../../RAFComponents/helpers/AutoCompleteMUIHelper";
import { hasPermission } from "../../../RAFComponents/helpers/PermissionHelper";
import {
  Guid,
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  getSaveRequest,
  getTimeDifferenceInMinutes,
  getUTCDateValue,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
  setZeroHours,
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import {
  ListRequest,
  ListServiceRequest,
} from "../../../RAFComponents/models/Common/ListRequest";
import { RolePermissionRow } from "../../../RAFComponents/models/Common/RolePermissionRow";
import { LookUpRow } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import {
  CareEsioEntity,
  CareEsioTab,
} from "../../../constants/CareESIO/CareEsioConstants";
import {
  Constants,
  ContentType,
  RAFActionStatus,
  RAFGridViewDisplayname,
  RAFShiftActionStatus,
  StorageKey,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import {
  getDurationDisplayText,
  getStartDateStartTimeEndTimeDisplayValue,
} from "../../CareESIO/CareShiftLog/CareBillingTimesheet/BillingTimesheetTemplateHelper";
import { InvoiceTransactionRow } from "../../CareESIO/CareShiftLog/CareBillingTimesheet/CareBilling/InvoiceTransactionRow";
import { PayrollTransactionRow } from "../../CareESIO/CareShiftLog/CareBillingTimesheet/TimeSheet/PayrollTransactionRow";
import { SaveShiftActivity } from "../../CareESIO/CareShiftLog/CareShiftLogHelper";
import {
  EmployeeAvailableDisplayResponseRow,
  EmployeeAvailableResponseRow,
  getEmployeeAvailability,
  getEmployeeCareTeamAvailability,
} from "../../CareESIO/Employee/EmployeeHelper/EmployeeHelper";
import { TenantSettingsRow } from "../../Common/TenantSettings/TenantSettingsRow";
import { saveTaskWithReminders } from "../Task/TaskHelper";
import { TaskRow } from "../Task/TaskRow";
import { TimeSheetSummaryModuleName } from "./ServiceTimesheetSummary/ServiceTimesheetSummaryHelper";
import { ServiceTransactionAvailabilityRow } from "./ServiceTransactionAvailabilityRow";
import { ServiceTransactionRow } from "./ServiceTransactionRow";
import { ShiftOfferRecipientShiftOfferRow } from "./ShiftOffer/ShiftOfferRecipientShiftOfferRow";

export enum RAFServiceTransactionShiftType {
  Active = 'Active',
  Passive = 'Passive'
}

export enum RAFServiceTransactionType {
  Shift = "Shift",
  Travel = "Travel",
}

export class ShiftByAssigneeListRequest extends ListServiceRequest {
  ClientUID?: string;
  AssigneeUID?: string;
  StartDate?: Date;
  EndDate?: Date;
  Status?: string;
  IncludeAvailability?: boolean;
  IncludeUnassigned?: boolean;
  SummaryTabName?: string;
}

export class InvoiceTransactionRequest extends ListRequest {
  ShiftUID?: string;
}

export class InvoiceTransactionClientRequest extends ListRequest {
  ClientUID?: string;
}

export class UserShiftSummaryListRequest extends ListServiceRequest {
  AssigneeUID?: string;
  SummaryTabName?: string;
}

export class ClientShiftSummaryListRequest extends ListServiceRequest {
  ClientUID?: string;
  SummaryTabName?: string;
}

export class RAFServiceTransactionDurationSummaryRow {
  UID?: string;
  Value?: string;
  DurationInMins?: number;
}

export const ShiftSummaryTabNames = {
  All: "All",
  WithRoster: "With Roster",
  WithoutRoster: "Without Roster",
};

export const DateRangeTypes = {
  Today: "Today",
  Day: "Day",
  ThisWeek: "Week",
  ThisMonth: "Month",
  Fortnight: "Fortnight",
  Custom: "Custom Date",
};

export const ServiceTransactionStates = {
  All: "All",
  Draft: "Draft",
  Published: "Published",
};

export const ServiceTransactionNotesRating = {
  All: "All",
  NotesMissing: "Notes Missing",
  Minimal: "Minimal",
  Short: "Short",
  Moderate: "Moderate",
  Detailed: "Detailed",
  Elaborate: "Elaborate",
};

export class ScheduleFilterRow {
  Entity?: string;
  RelatedToType?: string;
  RelatedTo?: string;
  RelatedToUID?: string;
  CareRecipient?: string;
  CareRecipientUID?: string;
  Assignee?: string;
  AssigneeUID?: string;
  DateRange?: string;
  Status: string;
  TimeChangeRequestStatus?: string;
  SelectedDate: Date;
  StartDate: Date;
  EndDate: Date;
  State?: string;
  IncludeAvailability?: boolean;
  ShowClientsWithoutRoster?: boolean;
  ShowEmployeesWithoutShift?: boolean;
  NotesRating?: string;
  ActivityFormType?: string[]; //onlyfor support log
  StartDateTimeValue?: Date; //onlyfor support log
  EndDateTimeValue?: Date; //onlyfor support log
  PayPeriod?: string;
  Client?: string;
  ClientUID?: string;
  Account?: string;
  AccountUID?: string;
}

export class AssigneeCardContentListItemRow {
  employeeAvailability: EmployeeAvailableDisplayResponseRow[];
  allEmployeeLookUpList: LookUpRow[];
  careTeamAvailability: EmployeeAvailableDisplayResponseRow[];
  allCareTeamList: EmployeeAvailableDisplayResponseRow[];
}

// export const getServiceTransactionDisplayName = () => {
//   return new Promise<string>(async (resolve) => {
//     const serviceTransactionEntity = await getEntityByObjectName({
//       ObjectName: ConvertSystemName(RAFEntityName.ServiceTransaction),
//     });

//     const serviceTransactionDisplayName = isNotNullAndUndefined(
//       serviceTransactionEntity
//     )
//       ? serviceTransactionEntity.DisplayName
//       : CareEsioTab.ShiftLog.DisplayName;
//     resolve(serviceTransactionDisplayName);
//   });
// };

// export const getServiceTransactionCollectionName = () => {
//   return new Promise<string>(async (resolve) => {
//     const serviceTransactionEntity = await getEntityByObjectName({
//       ObjectName: ConvertSystemName(RAFEntityName.ServiceTransaction),
//     });

//     const serviceTransactionCollectionName = isNotNullAndUndefined(
//       serviceTransactionEntity
//     )
//       ? serviceTransactionEntity.CollectionName
//       : CareEsioTab.ShiftLog.CollectionName;
//     resolve(serviceTransactionCollectionName);
//   });
// };

export const getServiceTransactionDisplayNameFromStorage = () => {
  const simpleEntityStorage = isNotNullAndUndefined(
    RAFEntityName.ServiceTransaction
  )
    ? getSessionStorage(
      StorageKey.entity_modulename + RAFEntityName.ServiceTransaction,
      true
    )
    : null;
  let entityRow: EntityRow =
    isNotNullAndUndefined(simpleEntityStorage) &&
      !IsNullOrWhiteSpace(simpleEntityStorage) &&
      isNotNullAndUndefined(JSON.parse(simpleEntityStorage))
      ? JSON.parse(simpleEntityStorage)
      : null;

  const serviceTransactionDisplayName = isNotNullAndUndefined(entityRow)
    ? entityRow.DisplayName
    : CareEsioTab.ShiftLog.DisplayName;
  return serviceTransactionDisplayName;
};

export const getServiceTransactionCollectionNameFromStorage = () => {
  const simpleEntityStorage = isNotNullAndUndefined(
    RAFEntityName.ServiceTransaction
  )
    ? getSessionStorage(
      StorageKey.entity_modulename + RAFEntityName.ServiceTransaction,
      true
    )
    : null;
  let entityRow: EntityRow =
    isNotNullAndUndefined(simpleEntityStorage) &&
      !IsNullOrWhiteSpace(simpleEntityStorage) &&
      isNotNullAndUndefined(JSON.parse(simpleEntityStorage))
      ? JSON.parse(simpleEntityStorage)
      : null;

  const serviceTransactionCollectionName = isNotNullAndUndefined(entityRow)
    ? entityRow.CollectionName
    : //? `My ${entityRow.CollectionName}`
    CareEsioTab.ShiftLog.CollectionName;

  return serviceTransactionCollectionName;
};

export const isServiceTransactionValidStartAndEndDateRange = (
  formStartDate: Date,
  formEndDate: Date,
  includeTimePartForValidation: boolean
) => {
  if (
    isNotNullAndUndefined(formStartDate) &&
    isNotNullAndUndefined(formEndDate)
  ) {
    const startDate = new Date(formStartDate);
    const endDate = new Date(formEndDate);

    const startDayDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );

    const endDayDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );

    const startDateSeconds =
      startDate.getHours() * 3600 +
      startDate.getMinutes() * 60 +
      startDate.getSeconds();
    const endDateSeconds =
      endDate.getHours() * 3600 +
      endDate.getMinutes() * 60 +
      endDate.getSeconds();

    const startDateMinutes = startDate.getHours() * 60 + startDate.getMinutes();
    const endDateMinutes = endDate.getHours() * 60 + endDate.getMinutes();

    //check if start date is greater than end date
    if (startDayDate.getTime() > endDayDate.getTime()) {
      showWarningToast(
        "Start Date should be less than End Date. Please select different dates."
      );
      return false;
    } else if (startDayDate.getTime() === endDayDate.getTime()) {
      //if start and end date are same
      // showWarningToast(
      //   "Start Date and End Date cannot be the same. Please select different dates."
      // );
      // return false;

      if (includeTimePartForValidation && startDateMinutes >= endDateMinutes) {
        //if start time is greater than or equal to end time
        showWarningToast(
          "Start Date Time should be less than End Date Time. Please select different time."
        );
        return false;
      }
    }
    // else if (
    //   startDateSeconds! > endDateSeconds ||
    //   startDateSeconds === endDateSeconds
    // ) {
    //   //if start time is greater than or equal to end time
    //   showWarningToast(
    //     "Start Date Time should be greater than End Date Time. Please select different time."
    //   );
    //   return false;
    // }
    else if (
      includeTimePartForValidation &&
      startDateMinutes >= endDateMinutes
    ) {
      //if start time is greater than or equal to end time
      showWarningToast(
        "Start Date Time should be less than End Date Time. Please select different time."
      );
      return false;
    }
    return true;
  }
  return false;
};

export function saveServiceTransaction(
  serviceTransactionRow: ServiceTransactionRow
) {
  return new Promise<ServiceTransactionRow>((resolve) => {
    let taskToSubmit = { ...serviceTransactionRow };

    if (
      taskToSubmit.RelatedToUID === null ||
      taskToSubmit.RelatedToType === null
    ) {
      taskToSubmit.RelatedToType = null;
      taskToSubmit.RelatedToUID = null;
      taskToSubmit.RelatedTo = null;
    }

    if (
      isNotNullAndUndefined(taskToSubmit.Priority) &&
      taskToSubmit.Priority === "Normal"
    ) {
      taskToSubmit.Priority = null;
    }

    const duration = getTimeDifferenceInMinutes(
      getUTCDateValue(taskToSubmit.StartDate),
      getUTCDateValue(taskToSubmit.EndDate)
    );
    if (
      IsNotNullOrWhiteSpace(duration) &&
      duration > 0 &&
      duration < 2111111111
    ) {
      //2111111111 is max value of duration in db
      taskToSubmit.Duration = duration;
    } else if (isNotNullAndUndefined(taskToSubmit.Duration)) {
      taskToSubmit.Duration = null;
    }

    const actualDuration = getTimeDifferenceInMinutes(
      getUTCDateValue(taskToSubmit.ActualStartDate),
      getUTCDateValue(taskToSubmit.ActualEndDate)
    );
    if (
      IsNotNullOrWhiteSpace(actualDuration) &&
      actualDuration > 0 &&
      actualDuration < 2111111111
    ) {
      //2111111111 is max value of duration in db
      taskToSubmit.ActualDuration = actualDuration;
    } else if (isNotNullAndUndefined(taskToSubmit.ActualDuration)) {
      taskToSubmit.ActualDuration = null;
    }

    const URL = "ServiceTransaction/Save";

    repositoryActions
      .postDataAndGetResponse(
        URL,
        getSaveRequest(taskToSubmit, taskToSubmit.UID),
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          serviceTransactionRow.Title = response.data.ObjectName;
          serviceTransactionRow.UID = response.data.EntityId;
          resolve(serviceTransactionRow);
        } else {
          resolve(null);
        }
      })
      .catch((error) => resolve(null));
  });
}

export const convertScheduleFilterRowRAFCustomFilter = (
  scheduleFilterRow: ScheduleFilterRow,
  relatedToUID: string,
  shiftType?: "Billing" | "Payroll" | "Default" | "Invoice" | 'ShiftOffer'
): RAFCustomFilter[] => {
  let relatedToFilters: RAFCustomFilter[] = [];
  const filterValues: ScheduleFilterRow = isNotNullAndUndefined(
    scheduleFilterRow
  )
    ? { ...scheduleFilterRow }
    : null;
  if (isNotNullAndUndefined(filterValues)) {
    const dateRage = filterValues.DateRange;

    const startDateTimeValue = filterValues.StartDateTimeValue;
    const endDateTimeValue = filterValues.EndDateTimeValue;

    const startDate = setZeroHours(filterValues.StartDate);
    const endDate = setZeroHours(filterValues.EndDate);

    const selectedStatus = filterValues.Status;

    const realtedCareRecipientUID = isNotNullAndUndefined(relatedToUID)
      ? relatedToUID
      : isNotNullAndUndefined(filterValues.CareRecipientUID)
        ? filterValues.CareRecipientUID
        : filterValues.RelatedToUID;

    delete filterValues.RelatedToType;
    delete filterValues.RelatedTo;
    delete filterValues.RelatedToUID;
    delete filterValues.DateRange;
    delete filterValues.Assignee;
    delete filterValues.StartDate;
    delete filterValues.EndDate;
    delete filterValues.SelectedDate;
    delete filterValues.Entity;
    delete filterValues.Status;
    delete filterValues.TimeChangeRequestStatus;
    delete filterValues.StartDateTimeValue;
    delete filterValues.EndDateTimeValue;
    delete filterValues.ActivityFormType;
    delete filterValues.CareRecipient;
    delete filterValues.CareRecipientUID;
    delete filterValues.PayPeriod;
    delete filterValues.IncludeAvailability;
    delete filterValues.ShowClientsWithoutRoster;
    delete filterValues.ShowEmployeesWithoutShift;

    if (filterValues.State === ServiceTransactionStates.All) {
      delete filterValues.State;
    }

    if (filterValues.NotesRating === ServiceTransactionNotesRating.All) {
      delete filterValues.NotesRating;
    }

    if (isNotNullAndUndefined(realtedCareRecipientUID)) {
      let careRecipientFilter: RAFCustomFilter = {};
      let careRecipientFilterVal: string[] = [];
      careRecipientFilterVal.push(realtedCareRecipientUID);
      careRecipientFilter.Operator = RAFCustomOperator.Equal;
      careRecipientFilter.Value = careRecipientFilterVal;
      careRecipientFilter.Field =
        shiftType === "Invoice"
          ? "ClientUID"
          : propertyOf<ServiceTransactionRow>("RelatedToUID");

      relatedToFilters.push(careRecipientFilter);
    }

    if (
      selectedStatus !== "AllStatus" &&
      isNotNullAndUndefined(selectedStatus)
    ) {
      let statusFilter: RAFCustomFilter = {};
      let statusFilterVal: string[] = [];
      statusFilterVal.push(selectedStatus);
      statusFilter.Operator = RAFCustomOperator.Equal;
      statusFilter.Value = statusFilterVal;
      statusFilter.Field =
        shiftType === "Billing"
          ? propertyOf<ServiceTransactionRow>("BillingStatus")
          : shiftType === "Payroll"
            ? propertyOf<ServiceTransactionRow>("TimesheetStatus")
            : shiftType === 'ShiftOffer' ? propertyOf<ShiftOfferRecipientShiftOfferRow>('ResponseStatus') : propertyOf<ServiceTransactionRow>("Status");
      relatedToFilters.push(statusFilter);
    }

    if (
      dateRage === DateRangeTypes.Custom &&
      isNotNullAndUndefined(startDateTimeValue) &&
      isNotNullAndUndefined(endDateTimeValue)
    ) {
      const dateFilterFiled =
        //shiftType === "Payroll"
        //  ? propertyOf<ServiceTransactionRow>("ActualStartDate"):
        shiftType === "Invoice"
          ? "InvoiceDate"
          : propertyOf<ServiceTransactionRow>("StartDate");
      let evalue1Filter: RAFCustomFilter = {};
      let evalue1FilterVal: string[] = [];
      evalue1FilterVal.push(startDateTimeValue as any); //28/12/2023 post  -- 27/12/2023/18:30:00 somthing greater than equal
      evalue1Filter.Operator = RAFCustomOperator.GreaterThanOrEqual;
      evalue1Filter.Value = evalue1FilterVal;
      evalue1Filter.Field = dateFilterFiled;
      relatedToFilters.push(evalue1Filter);

      let evalue2Filter: RAFCustomFilter = {};
      let evalue2FilterVal: string[] = [];
      evalue2FilterVal.push(endDateTimeValue as any);
      evalue2Filter.Operator = RAFCustomOperator.LesserThan;
      evalue2Filter.Value = evalue2FilterVal;
      evalue2Filter.Field = dateFilterFiled;
      relatedToFilters.push(evalue2Filter);
    } else if (
      isNotNullAndUndefined(startDate) &&
      isNotNullAndUndefined(endDate)
    ) {
      const dateFilterFiled =
        //shiftType === "Payroll"
        //  ? propertyOf<ServiceTransactionRow>("ActualStartDate"):
        shiftType === "Invoice"
          ? "InvoiceDate"
          : shiftType === 'ShiftOffer' ? propertyOf<ShiftOfferRecipientShiftOfferRow>('OfferDate') : propertyOf<ServiceTransactionRow>("StartDate");
      let evalue1Filter: RAFCustomFilter = {};
      let evalue1FilterVal: string[] = [];
      evalue1FilterVal.push(startDate as any); //28/12/2023 post  -- 27/12/2023/18:30:00 somthing greater than equal
      evalue1Filter.Operator = RAFCustomOperator.GreaterThanOrEqual;
      evalue1Filter.Value = evalue1FilterVal;
      evalue1Filter.Field = dateFilterFiled;
      relatedToFilters.push(evalue1Filter);

      let evalue2Filter: RAFCustomFilter = {};
      let evalue2FilterVal: string[] = [];
      //if (moment(eValue1).isSame(eValue2)) {  //30/12/2023 post  -- 30/12/2023/18:30:00 somthing less than
      let nextDay = moment(endDate).add(1, "days").toDate();
      evalue2FilterVal.push(nextDay as any);
      evalue2Filter.Operator = RAFCustomOperator.LesserThan;
      // } else {
      //   evalue2FilterVal.push(eValue2 as any); //30/12/2023 post  -- 29/12/2023/18:30:00 somthing less than or equal
      //   evalue2Filter.Operator = RAFCustomOperator.LesserThanOrEqual;
      // }

      evalue2Filter.Value = evalue2FilterVal;
      evalue2Filter.Field = dateFilterFiled;
      relatedToFilters.push(evalue2Filter);
    }

    let sourceObject = filterValues;
    for (let key in sourceObject) {
      let relatedFilter: RAFCustomFilter = {};
      let relatedFilterVal: string[] = [];
      relatedFilterVal.push(sourceObject[key]);
      relatedFilter.Operator = RAFCustomOperator.Equal;
      relatedFilter.Value = relatedFilterVal;
      relatedFilter.Field = key;

      if (isNotNullAndUndefined(sourceObject[key]))
        relatedToFilters.push(relatedFilter);
    }
  }

  return relatedToFilters;
};

export const getServiceTransactionTitleHourMinuteByDate = (
  startDate,
  endDate
) => {
  let title = "";
  if (isNotNullAndUndefined(startDate) && isNotNullAndUndefined(endDate)) {
    const startTime = moment(startDate).toDate();
    const endTime = moment(endDate).toDate();
    const startHour = startTime.getHours();
    const startMinute = startTime.getMinutes();
    const endHour = endTime.getHours();
    const endMinute = endTime.getMinutes();
    const startHourString = startHour < 10 ? "0" + startHour : startHour;
    const startMinuteString =
      startMinute < 10 ? "0" + startMinute : startMinute;
    const endHourString = endHour < 10 ? "0" + endHour : endHour;
    const endMinuteString = endMinute < 10 ? "0" + endMinute : endMinute;
    title = `${startHourString}:${startMinuteString} - ${endHourString}:${endMinuteString}`;
  }
  return title;
};

export const getServiceTransactionTitleHourMinuteAMPMByDate = (
  startDate: Date,
  endDate: Date
) => {
  let title = "";
  if (isNotNullAndUndefined(startDate) && isNotNullAndUndefined(endDate)) {
    const startTime = moment(startDate).format("hh:mm A");
    const endTime = moment(endDate).format("hh:mm A");
    title = `${startTime} - ${endTime}`;
  }
  return title;
};

export const getServiceTransactionTitleByStartEndDate = (
  startDate: Date,
  endDate: Date
) => {
  let title = "";
  if (isNotNullAndUndefined(startDate) && isNotNullAndUndefined(endDate)) {
    const startTime = moment(startDate).format("hh:mm A");
    const endTime = moment(endDate).format("hh:mm A");
    title = `${moment(startDate).format(
      "DD/MM/YYYY"
    )}  ${startTime} - ${endTime}`;
  }
  return title;
};

export class ServiceTransactionSummaryRow {
  Next7Days: number;
  RecentlyCompleted: number;
  MyTasks: number;
}

export const getServiceTransactionSummaryCount = () => {
  return new Promise<ServiceTransactionSummaryRow>((resolve) => {
    let listServiceRequest = new ListServiceRequest();
    repositoryActions
      .postDataAndGetResponse(
        "ServiceTransaction/Summary",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entity)
        ) {
          resolve(response.data.Entity);
        } else {
          resolve(null);
        }
      })
      .catch((error) => resolve(null));
  });
};

export const updateServiceTransactionRow = (
  initialValues: ServiceTransactionRow
) => {
  return new Promise<{ entityId: string; objectName: string; }>((resolve) => {
    if (isNotNullAndUndefined(initialValues)) {
      repositoryActions
        .postDataAndGetResponse(
          "ServiceTransaction/Save",
          getSaveRequest(initialValues, initialValues.UID),
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            resolve({
              entityId: response.data.EntityId,
              objectName: response.data.ObjectName,
            });
          } else {
            resolve(null);
          }
        })
        .catch((error) => resolve(null));
    } else {
      resolve(null);
    }
  });
};

export const updateServiceTransactionSatusByServiceTransactionUID = (
  taskUID: string,
  taskStatus: string,
  taskActionDate?: Date,
  actualDuration?: number
) => {
  return new Promise<{ entityId: string; objectName: string; }>((resolve) => {
    if (isNotNullAndUndefined(taskUID) && isNotNullAndUndefined(taskStatus)) {
      const initialValues = { UID: taskUID, Status: taskStatus };
      if (
        taskStatus === RAFShiftActionStatus.Completed &&
        isNotNullAndUndefined(taskActionDate)
      ) {
        initialValues["ActualEndDate"] = taskActionDate;
        if (isNotNullAndUndefined(actualDuration)) {
          initialValues["ActualDuration"] = actualDuration;
        }
      }
      if (
        taskStatus === RAFShiftActionStatus.InProgress &&
        isNotNullAndUndefined(taskActionDate)
      ) {
        initialValues["ActualStartDate"] = taskActionDate;
      }

      repositoryActions
        .postDataAndGetResponse(
          "ServiceTransaction/Save",
          getSaveRequest(initialValues, taskUID),
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            resolve({
              entityId: response.data.EntityId,
              objectName: response.data.ObjectName,
            });
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const getServiceTransactionListServiceRequest = (
  relatedToUID: string,
  skip: number = 0,
  take: number = 10,
  relatedFilters: RAFCustomFilter[],
  scheduleFilterRow: ScheduleFilterRow,
  viewName: "my_shift" | "all_shift",
  taskStatus: string[]
) => {
  let customFilter: RAFCustomFilter = {};
  customFilter.Condition = "and";
  customFilter.Rules = [];

  if (viewName === "my_shift") {
    let assigneeFilter: RAFCustomFilter = {};
    let assigneeFilterVal: string[] = [];
    assigneeFilterVal.push(msalInstance.currentUserId);
    assigneeFilter.Operator = RAFCustomOperator.Equal;
    assigneeFilter.Value = assigneeFilterVal;
    assigneeFilter.Field = propertyOf<ServiceTransactionRow>("AssigneeUID");
    customFilter.Rules.push(assigneeFilter);
  }

  // if (isNotEmptyArray(taskTypes)) {
  //     let customTaskFilter: RAFCustomFilter = {};
  //     customTaskFilter.Condition = "or";
  //     customTaskFilter.Rules = [];
  //     taskTypes.forEach((taskType) => {
  //         let taskTypeFilter: RAFCustomFilter = {};
  //         let taskTypeFilterVal: string[] = [];
  //         taskTypeFilterVal.push(taskType);
  //         taskTypeFilter.Operator = RAFCustomOperator.Equal;
  //         taskTypeFilter.Value = taskTypeFilterVal;
  //         taskTypeFilter.Field = propertyOf<ServiceTransactionRow>("Type");
  //         customTaskFilter.Rules.push(taskTypeFilter);
  //     });
  //     customFilter.Rules.push(customTaskFilter);
  // }

  if (isNotEmptyArray(relatedFilters)) {
    relatedFilters.forEach((filter) => {
      customFilter.Rules.push(filter);
    });
  }

  const scheduleFilter = convertScheduleFilterRowRAFCustomFilter(
    scheduleFilterRow,
    relatedToUID
  );
  if (isNotEmptyArray(scheduleFilter)) {
    scheduleFilter.forEach((filter) => {
      customFilter.Rules.push(filter);
    });
  }

  const filterStatusValue = isNotNullAndUndefined(scheduleFilterRow)
    ? scheduleFilterRow.Status
    : null;

  if (isNotEmptyArray(taskStatus) && filterStatusValue === "AllStatus") {
    let customTaskStatusFilter: RAFCustomFilter = {};
    customTaskStatusFilter.Condition = "or";
    customTaskStatusFilter.Rules = [];
    taskStatus.forEach((taskType) => {
      let taskStatusFilter: RAFCustomFilter = {};
      let taskStatusFilterVal: string[] = [];
      taskStatusFilterVal.push(taskType);
      taskStatusFilter.Operator = RAFCustomOperator.Equal;
      taskStatusFilter.Value = taskStatusFilterVal;
      taskStatusFilter.Field = propertyOf<ServiceTransactionRow>("Status");
      customTaskStatusFilter.Rules.push(taskStatusFilter);
    });
    customFilter.Rules.push(customTaskStatusFilter);
  }

  let sortBy = {
    field: propertyOf<ServiceTransactionRow>("StartDate"),
  };
  let sortQuery: string[] = [];
  if (isNotNullAndUndefined(sortBy) && isNotNullAndUndefined(sortBy.field)) {
    sortQuery.push(sortBy.field);
  }

  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.CustomFilter = customFilter;
  listServiceRequest.Take = take;
  listServiceRequest.Skip = skip;
  listServiceRequest.Sort = sortQuery;

  return listServiceRequest;
};

export const getAllServiceTransactionList = (
  relatedToUID: string,
  skip: number = 0,
  take: number = 10,
  relatedFilters?: RAFCustomFilter[],
  scheduleFilterRow?: ScheduleFilterRow,
  viewName?: "my_shift" | "all_shift",
  taskStatus?: string[]
) => {
  return new Promise<ServiceTransactionRow[]>((resolve) => {
    const listServiceRequest: ListServiceRequest =
      getServiceTransactionListServiceRequest(
        relatedToUID,
        skip,
        take,
        relatedFilters,
        scheduleFilterRow,
        viewName,
        taskStatus
      );

    repositoryActions
      .postDataAndGetResponse(
        "ServiceTransaction/List",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      });
  });
};

export const getAllServiceTransactionAvailabilityCalendarList = (
  relatedToUID: string,
  skip: number = 0,
  take: number = 10,
  relatedFilters?: RAFCustomFilter[],
  scheduleFiltersRow?: ScheduleFilterRow,
  viewName?: "my_shift" | "all_shift",
  taskStatus?: string[]
) => {
  return new Promise<ServiceTransactionAvailabilityRow[]>(async (resolve) => {
    const scheduleFilterRow: ScheduleFilterRow = R.clone(scheduleFiltersRow);
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    if (isNotEmptyArray(relatedFilters)) {
      relatedFilters.forEach((filter) => {
        customFilter.Rules.push(filter);
      });
    }

    let sortBy = {
      field: propertyOf<ServiceTransactionRow>("StartDate"),
    };
    let sortQuery: string[] = [];
    if (isNotNullAndUndefined(sortBy) && isNotNullAndUndefined(sortBy.field)) {
      sortQuery.push(sortBy.field);
    }

    let shiftByAssigneeListRequest = new ShiftByAssigneeListRequest();
    shiftByAssigneeListRequest.Take = take;
    shiftByAssigneeListRequest.Skip = skip;
    shiftByAssigneeListRequest.Sort = sortQuery;

    const relatedToType = isNotNullAndUndefined(scheduleFilterRow)
      ? scheduleFilterRow.RelatedToType
      : null;

    const clientUID = isNotNullAndUndefined(relatedToUID)
      ? relatedToUID
      : isNotNullAndUndefined(scheduleFilterRow)
        ? scheduleFilterRow.RelatedToUID
        : null;

    const assigneeUID =
      viewName === "my_shift"
        ? msalInstance.currentUserId
        : isNotNullAndUndefined(scheduleFilterRow)
          ? scheduleFilterRow.AssigneeUID
          : null;

    const startDate: Date = isNotNullAndUndefined(scheduleFilterRow)
      ? scheduleFilterRow.StartDate
      : null;
    const endDate: Date = isNotNullAndUndefined(scheduleFilterRow)
      ? scheduleFilterRow.EndDate
      : null;
    const status = isNotNullAndUndefined(scheduleFilterRow)
      ? scheduleFilterRow.Status
      : null;

    const includeAvailability = isNotNullAndUndefined(scheduleFilterRow)
      ? scheduleFilterRow.IncludeAvailability
      : false;

    const showEmployeesWithoutShift = isNotNullAndUndefined(scheduleFilterRow)
      ? scheduleFilterRow.ShowEmployeesWithoutShift
      : false;

    const showClientsWithoutRoster = isNotNullAndUndefined(scheduleFilterRow)
      ? scheduleFilterRow.ShowClientsWithoutRoster
      : false;

    if (isNotNullAndUndefined(assigneeUID)) {
      shiftByAssigneeListRequest.AssigneeUID = assigneeUID;
    }

    if (isNotNullAndUndefined(clientUID)) {
      shiftByAssigneeListRequest.ClientUID = clientUID;
    }

    if (relatedToType === RAFEntityName.Employee) {
      if (showEmployeesWithoutShift !== true && viewName === "all_shift") {
        shiftByAssigneeListRequest.SummaryTabName =
          ShiftSummaryTabNames.WithRoster;
      }
    } else if (relatedToType === CareEsioEntity.CareRecipient.EntityName) {
      if (showClientsWithoutRoster !== true && viewName === "all_shift") {
        shiftByAssigneeListRequest.SummaryTabName =
          ShiftSummaryTabNames.WithRoster;
      }
    }

    if (
      isNotNullAndUndefined(assigneeUID) ||
      isNotNullAndUndefined(clientUID)
    ) {
      if (viewName === "all_shift") {
        shiftByAssigneeListRequest.IncludeUnassigned = true;
      }
    }

    if (viewName === "my_shift") {
      shiftByAssigneeListRequest.IncludeAvailability = false;
    } else if (relatedToType === RAFEntityName.Employee) {
      shiftByAssigneeListRequest.IncludeAvailability =
        includeAvailability === true ? true : false;
    }

    if (IsNotNullOrWhiteSpace(startDate) && IsNotNullOrWhiteSpace(endDate)) {
      const isSameDate =
        startDate.getFullYear() === endDate.getFullYear() &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getDate() === endDate.getDate();
      if (isSameDate) {
        const nextDay = moment(endDate).add(1, "days").toDate();
        shiftByAssigneeListRequest.StartDate = startDate;
        shiftByAssigneeListRequest.EndDate = nextDay;
      } else {
        shiftByAssigneeListRequest.StartDate = startDate;
        const nextDay = moment(endDate).add(1, "days").toDate();
        shiftByAssigneeListRequest.EndDate = nextDay;
      }
    }

    if (isNotNullAndUndefined(status) && status !== "AllStatus") {
      shiftByAssigneeListRequest.Status = status;
    }

    if (isNotNullAndUndefined(scheduleFilterRow)) {
      delete scheduleFilterRow.RelatedToUID;
      delete scheduleFilterRow.RelatedTo;
      delete scheduleFilterRow.RelatedToType;
      delete scheduleFilterRow.Assignee;
      delete scheduleFilterRow.AssigneeUID;
      delete scheduleFilterRow.StartDate;
      delete scheduleFilterRow.EndDate;
      delete scheduleFilterRow.Status;
      delete scheduleFilterRow.TimeChangeRequestStatus;
      delete scheduleFilterRow.IncludeAvailability;
      delete scheduleFilterRow.ShowClientsWithoutRoster;
      delete scheduleFilterRow.ShowEmployeesWithoutShift;
    }

    const scheduleFilter = convertScheduleFilterRowRAFCustomFilter(
      scheduleFilterRow,
      null
    );
    if (isNotEmptyArray(scheduleFilter)) {
      scheduleFilter.forEach((filter) => {
        customFilter.Rules.push(filter);
      });
    }

    /*if (isNotEmptyArray(taskStatus) && status === "AllStatus") {
        let customTaskStatusFilter: RAFCustomFilter = {};
        customTaskStatusFilter.Condition = "or";
        customTaskStatusFilter.Rules = [];
        taskStatus.forEach((taskType) => {
          let taskStatusFilter: RAFCustomFilter = {};
          let taskStatusFilterVal: string[] = [];
          taskStatusFilterVal.push(taskType);
          taskStatusFilter.Operator = RAFCustomOperator.Equal;
          taskStatusFilter.Value = taskStatusFilterVal;
          taskStatusFilter.Field = propertyOf<ServiceTransactionRow>("Status");
          customTaskStatusFilter.Rules.push(taskStatusFilter);
        });
        customFilter.Rules.push(customTaskStatusFilter);
      }*/

    shiftByAssigneeListRequest.CustomFilter = customFilter;

    repositoryActions
      .postDataAndGetResponse(
        "ServiceTransaction/AvailabilityCalendar",
        shiftByAssigneeListRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotEmptyArray(response.data.Entities)
        ) {
          let availabilityList: ServiceTransactionAvailabilityRow[] =
            response.data.Entities;
          if (isNotEmptyArray(availabilityList)) {
            availabilityList = availabilityList.map((item) => {
              if (item.UID === null) {
                return {
                  ...item,
                  UID: `${Guid.newGuid()}_${item.AvailabilityType}`,
                  Title: getStartDateStartTimeEndTimeDisplayValue(
                    item.StartDate,
                    item.EndDate
                  ),
                };
              } else {
                return item;
              }
            });
          }

          resolve(availabilityList);
        } else {
          resolve(null);
        }
      });
  });
};

export const getServiceTransactionbyStatus = (
  relatedToUID: string,
  taskStatus?: string[],
  sortQuery?: string[],
  relatedFilters?: RAFCustomFilter[],
  scheduleFilterRow?: ScheduleFilterRow,
  takeCount?: number,
  viewName?: "my_shift" | "all_shift",
  shiftType?: "Billing" | "Payroll" | "Default",
  defaultURL?: string,
  defaultState?: string
) => {
  return new Promise<ServiceTransactionRow[]>((resolve, reject) => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    if (viewName === "my_shift") {
      let assigneeFilter: RAFCustomFilter = {};
      let assigneeFilterVal: string[] = [];
      assigneeFilterVal.push(msalInstance.currentUserId);
      assigneeFilter.Operator = RAFCustomOperator.Equal;
      assigneeFilter.Value = assigneeFilterVal;
      assigneeFilter.Field = propertyOf<ServiceTransactionRow>("AssigneeUID");
      customFilter.Rules.push(assigneeFilter);
    }

    if (
      isNotNullAndUndefined(defaultState) &&
      defaultState !== ServiceTransactionStates.All
    ) {
      let stateFilter: RAFCustomFilter = {};
      let stateFilterVal: string[] = [];
      stateFilterVal.push(defaultState);
      stateFilter.Operator = RAFCustomOperator.Equal;
      stateFilter.Value = stateFilterVal;
      stateFilter.Field = propertyOf<ServiceTransactionRow>("State");
      customFilter.Rules.push(stateFilter);
    }

    if (isNotEmptyArray(relatedFilters)) {
      relatedFilters.forEach((filter) => {
        customFilter.Rules.push(filter);
      });
    }

    const scheduleFilter = convertScheduleFilterRowRAFCustomFilter(
      scheduleFilterRow,
      relatedToUID,
      shiftType
    );
    if (isNotEmptyArray(scheduleFilter)) {
      scheduleFilter.forEach((filter) => {
        customFilter.Rules.push(filter);
      });
    }

    const filterStatusValue = isNotNullAndUndefined(scheduleFilterRow)
      ? scheduleFilterRow.Status
      : null;

    if (isNotEmptyArray(taskStatus) && filterStatusValue === "AllStatus") {
      let customTaskStatusFilter: RAFCustomFilter = {};
      customTaskStatusFilter.Condition = "or";
      customTaskStatusFilter.Rules = [];
      taskStatus.forEach((taskType) => {
        let taskStatusFilter: RAFCustomFilter = {};
        let taskStatusFilterVal: string[] = [];
        taskStatusFilterVal.push(taskType);
        taskStatusFilter.Operator = RAFCustomOperator.Equal;
        taskStatusFilter.Value = taskStatusFilterVal;
        taskStatusFilter.Field =
          shiftType === "Billing"
            ? propertyOf<ServiceTransactionRow>("BillingStatus")
            : propertyOf<ServiceTransactionRow>("Status");
        customTaskStatusFilter.Rules.push(taskStatusFilter);
      });
      customFilter.Rules.push(customTaskStatusFilter);
    }

    let listServiceRequest = new ListServiceRequest();

    if (IsNullOrWhiteSpace(defaultURL)) {
      listServiceRequest.CustomFilter = customFilter;
    }

    listServiceRequest.Take = takeCount ? takeCount : 10;
    listServiceRequest.Skip = 0;

    if (isNotEmptyArray(sortQuery)) {
      listServiceRequest.Sort = sortQuery;
    }

    const URL = IsNotNullOrWhiteSpace(defaultURL)
      ? defaultURL
      : "ServiceTransaction/List";

    repositoryActions
      .postDataAndGetResponse(
        URL,
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      });
  });
};

export const getTodaysServiceTransaction = () => {
  return new Promise<ServiceTransactionRow[]>((resolve, reject) => {
    let listServiceRequest = new ListServiceRequest();

    listServiceRequest.Take = 0;
    listServiceRequest.Skip = 0;

    const URL = "ServiceTransaction/Today";

    repositoryActions
      .postDataAndGetResponse(
        URL,
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      });
  });
};

export const getServiceTransactionItemCountByStatus = (
  relatedToUID: string,
  scheduleFilterRow: ScheduleFilterRow
) => {
  return new Promise<number>((resolve, reject) => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    let parentFilter: RAFCustomFilter = {};
    parentFilter.Operator = RAFCustomOperator.IsNull;
    parentFilter.Field = propertyOf<ServiceTransactionRow>("ParentUID");
    customFilter.Rules.push(parentFilter);

    let shiftTypeFilter: RAFCustomFilter = {};
    let shiftTypeFilterVal: string[] = [];
    shiftTypeFilterVal.push(RAFServiceTransactionType.Shift);
    shiftTypeFilter.Operator = RAFCustomOperator.Equal;
    shiftTypeFilter.Value = shiftTypeFilterVal;
    shiftTypeFilter.Field = propertyOf<ServiceTransactionRow>("Type");
    customFilter.Rules.push(shiftTypeFilter);

    const scheduleFilter = convertScheduleFilterRowRAFCustomFilter(
      scheduleFilterRow,
      null,
      "Default"
    );
    if (isNotEmptyArray(scheduleFilter)) {
      scheduleFilter.forEach((filter) => {
        customFilter.Rules.push(filter);
      });
    }

    let listServiceRequest = new ListServiceRequest();

    listServiceRequest.EntityName = RAFEntityName.ServiceTransaction;
    listServiceRequest["RelatedField"] =
      propertyOf<ServiceTransactionRow>("RelatedToUID");
    listServiceRequest["RelatedFieldValue"] = relatedToUID;

    listServiceRequest.CustomFilter = customFilter;

    const URL = "DataList/RelatedListSummary";

    repositoryActions
      .postDataAndGetResponse(
        URL,
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (isNotNullAndUndefined(response)) {
          if (response.status === 204) {
            resolve(null);
          } else if (isNotNullAndUndefined(response.data)) {
            resolve(response.data.TotalRows);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      });
  });
};

export function RetrieveServiceTransactionById(id: string) {
  return new Promise<ServiceTransactionRow>((resolve) => {
    if (IsNotNullOrWhiteSpace(id)) {
      repositoryActions
        .postDataAndGetResponse(
          "ServiceTransaction/Retrieve",
          { EntityId: id },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            resolve(response.data.Entity);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
}

//save service contract item start

export const saveShiftActivityList = async (
  serviceTransactionResponse: ServiceTransactionRow,
  selectedSupportTasks: string[],
  careInterventionSupportRows: any[],
  formLibrary: FormLibraryRow
) => {
  return new Promise<string[]>(async (resolve) => {
    if (
      isNotNullAndUndefined(serviceTransactionResponse) &&
      isNotEmptyArray(selectedSupportTasks)
    ) {
      const promises = selectedSupportTasks.map((x) =>
        saveShiftActivityByItem(
          serviceTransactionResponse,
          x,
          careInterventionSupportRows,
          formLibrary
        )
      );
      const results = await Promise.all(promises);
      resolve(results);
    } else {
      resolve(null);
    }
  });
};

const saveShiftActivityByItem = (
  serviceTransactionResponse: ServiceTransactionRow,
  selectedSupportTask: string,
  careInterventionSupportRows: any[],
  formLibrary: FormLibraryRow
) => {
  return new Promise<string>(async (resolve) => {
    if (
      isNotNullAndUndefined(serviceTransactionResponse) &&
      isNotNullAndUndefined(selectedSupportTask)
    ) {
      let careSupportNeed = careInterventionSupportRows.find(
        (x) => x.UID === selectedSupportTask
      );
      if (isNotNullAndUndefined(careSupportNeed)) {
        let newShiftActivity: any = {}; //Initialize newShiftActivity as an empty object
        newShiftActivity.title = careSupportNeed.Title;
        newShiftActivity.instructions = careSupportNeed.Note;
        newShiftActivity.care_recipientuid = careSupportNeed.CareRecipientUID;
        newShiftActivity.care_recipient = careSupportNeed.CareRecipient;
        newShiftActivity.related_goaluid = careSupportNeed.GoalUID;
        newShiftActivity.related_goal = careSupportNeed.Goal;
        newShiftActivity.support_needuid = careSupportNeed.UID;
        newShiftActivity.support_need = careSupportNeed.Title;
        newShiftActivity.status = RAFActionStatus.Planned;
        newShiftActivity.log_date = new Date();
        newShiftActivity.form_library = formLibrary.Title;
        newShiftActivity.form_libraryuid = formLibrary.UID;

        newShiftActivity.assignee = serviceTransactionResponse.Assignee;
        newShiftActivity.assigneeuid = serviceTransactionResponse.AssigneeUID;

        newShiftActivity.task = serviceTransactionResponse.Title;
        newShiftActivity.taskuid = serviceTransactionResponse.UID;

        newShiftActivity.start_date = serviceTransactionResponse.StartDate;
        //newShiftActivity.end_date = serviceTransactionResponse.EndDate;
        newShiftActivity.start_time = serviceTransactionResponse.StartDate;
        //newShiftActivity.end_time = serviceTransactionResponse.EndDate;

        let saveRequestData = getSaveRequest(newShiftActivity, null);
        saveRequestData.EntityName =
          CareEsioEntity.CareShiftActivity.EntityName;
        const newActivity = await SaveShiftActivity(saveRequestData);
        resolve(
          isNotNullAndUndefined(newActivity) ? newActivity.entityId : null
        );
      } else {
        resolve(null);
      }
    } else {
      resolve(null);
    }
  });
};

//save service contract item end

//save support need item start

export const SaveShiftPlannedActivityList = (
  serviceTransactionResponse: ServiceTransactionRow,
  selectedPlannedActivities: string[],
  plannedActivities: TaskRow[]
) => {
  return new Promise<string[]>(async (resolve) => {
    if (
      isNotNullAndUndefined(serviceTransactionResponse) &&
      isNotEmptyArray(selectedPlannedActivities)
    ) {
      const promises = selectedPlannedActivities.map((x) =>
        saveShiftPlannedTask(serviceTransactionResponse, x, plannedActivities)
      );
      const results = await Promise.all(promises);

      resolve(results);
    } else {
      resolve(null);
    }
  });
};

const saveShiftPlannedTask = (
  serviceTransactionResponse: ServiceTransactionRow,
  selectedPlannedActivity: string,
  plannedActivities: TaskRow[]
) => {
  return new Promise<string>(async (resolve) => {
    if (
      isNotNullAndUndefined(serviceTransactionResponse) &&
      isNotNullAndUndefined(selectedPlannedActivity)
    ) {
      const pannedActivity = plannedActivities.find(
        (x) => x.UID === selectedPlannedActivity
      );
      if (isNotNullAndUndefined(pannedActivity)) {
        let newTaskRow: TaskRow = R.clone(pannedActivity);
        newTaskRow.Assignee = isNotNullAndUndefined(newTaskRow.Assignee)
          ? pannedActivity.Assignee
          : serviceTransactionResponse.Assignee;
        newTaskRow.AssigneeUID = isNotNullAndUndefined(
          pannedActivity.AssigneeUID
        )
          ? pannedActivity.AssigneeUID
          : serviceTransactionResponse.AssigneeUID;

        newTaskRow.SecondaryRelatedTo = serviceTransactionResponse.Title;
        newTaskRow.SecondaryRelatedToUID = serviceTransactionResponse.UID;
        newTaskRow.SecondaryRelatedToType = RAFEntityName.ServiceTransaction;

        const newActivity = await saveTaskWithReminders(newTaskRow);

        resolve(newActivity);
      } else {
        resolve(null);
      }
    } else {
      resolve(null);
    }
  });
};

//save support need item end

export const createMultipleServiceTransactionRows = (
  serviceTransactionList: ServiceTransactionRow[]
) => {
  return new Promise<{ entityId: string; objectName: string; }[]>((resolve) => {
    if (isNotEmptyArray(serviceTransactionList)) {
      const submitValue: { Entity: ServiceTransactionRow; }[] =
        serviceTransactionList.map((x) => {
          return {
            Entity: x,
          };
        });
      repositoryActions
        .postDataAndGetResponse(
          "ServiceTransaction/SaveList",
          submitValue,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotEmptyArray(response.data)
          ) {
            resolve(response.data);
          } else {
            resolve(null);
          }
        })
        .catch((error) => resolve(null));
    } else {
      resolve(null);
    }
  });
};

export function getChangedScheduleFilterFieldCount(
  activeScheduleFilterRow: ScheduleFilterRow,
  defaultShiftFilter: ScheduleFilterRow,
  countFields?: string[]
) {
  let fields = [];
  if (isNotEmptyArray(countFields)) {
    fields = countFields;
  } else {
    fields = [
      propertyOf<ScheduleFilterRow>("AssigneeUID"),
      propertyOf<ScheduleFilterRow>("RelatedToUID"),
      propertyOf<ScheduleFilterRow>("CareRecipientUID"),
      propertyOf<ScheduleFilterRow>("State"),
      propertyOf<ScheduleFilterRow>("Status"),
      propertyOf<ScheduleFilterRow>("NotesRating"),
      propertyOf<ScheduleFilterRow>("PayPeriod"),
      //propertyOf<ScheduleFilterRow>('StartDate'),
      //propertyOf<ScheduleFilterRow>('EndDate'),
    ];
  }

  let count = 0;

  if (
    isNotNullAndUndefined(activeScheduleFilterRow) &&
    isNotNullAndUndefined(defaultShiftFilter)
  ) {
    if (fields.includes("StartDate") || fields.includes("EndDate")) {
      const startDateChanged =
        isNotNullAndUndefined(activeScheduleFilterRow["StartDate"]) &&
        isNotNullAndUndefined(defaultShiftFilter["StartDate"]) &&
        activeScheduleFilterRow.StartDate.getTime() !==
        defaultShiftFilter.StartDate.getTime();

      const endDateChanged =
        isNotNullAndUndefined(activeScheduleFilterRow["EndDate"]) &&
        isNotNullAndUndefined(defaultShiftFilter["EndDate"]) &&
        activeScheduleFilterRow.EndDate.getTime() !==
        defaultShiftFilter.EndDate.getTime();

      if (startDateChanged || endDateChanged) {
        count++;
      }
    }

    fields.forEach((field) => {
      if (
        field !== "StartDate" &&
        field !== "EndDate" &&
        activeScheduleFilterRow[field] !== defaultShiftFilter[field]
      ) {
        if (
          IsNullOrWhiteSpace(activeScheduleFilterRow[field]) &&
          IsNullOrWhiteSpace(defaultShiftFilter[field])
        ) {
        } else {
          count++;
        }
      }
    });
  }

  return count;
}

export const getUserServiceTransactiontDurationSummary = (
  relatedToUID: string,
  skip: number = 0,
  take: number = 10,
  relatedFilters?: RAFCustomFilter[],
  scheduleFilterRow?: ScheduleFilterRow,
  viewName?: "my_shift" | "all_shift",
  taskStatus?: string[]
) => {
  return new Promise<RAFServiceTransactionDurationSummaryRow[]>((resolve) => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    const showEmployeesWithoutShift = isNotNullAndUndefined(scheduleFilterRow)
      ? scheduleFilterRow.ShowEmployeesWithoutShift
      : null;

    let assigneeFilterVal = null;
    if (viewName === "my_shift") {
      assigneeFilterVal = msalInstance.currentUserId;
      // let assigneeFilter: RAFCustomFilter = {};
      // let assigneeFilterVal: string[] = [];
      // assigneeFilterVal.push(msalInstance.currentUserId);
      // assigneeFilter.Operator = RAFCustomOperator.Equal;
      // assigneeFilter.Value = assigneeFilterVal;
      // assigneeFilter.Field = propertyOf<ServiceTransactionRow>("AssigneeUID");
      // customFilter.Rules.push(assigneeFilter);
    }

    // if (isNotEmptyArray(taskTypes)) {
    //     let customTaskFilter: RAFCustomFilter = {};
    //     customTaskFilter.Condition = "or";
    //     customTaskFilter.Rules = [];
    //     taskTypes.forEach((taskType) => {
    //         let taskTypeFilter: RAFCustomFilter = {};
    //         let taskTypeFilterVal: string[] = [];
    //         taskTypeFilterVal.push(taskType);
    //         taskTypeFilter.Operator = RAFCustomOperator.Equal;
    //         taskTypeFilter.Value = taskTypeFilterVal;
    //         taskTypeFilter.Field = propertyOf<ServiceTransactionRow>("Type");
    //         customTaskFilter.Rules.push(taskTypeFilter);
    //     });
    //     customFilter.Rules.push(customTaskFilter);
    // }

    if (isNotEmptyArray(relatedFilters)) {
      relatedFilters.forEach((filter) => {
        customFilter.Rules.push(filter);
      });
    }

    if (isNotNullAndUndefined(scheduleFilterRow)) {
      const filterValues: ScheduleFilterRow = isNotNullAndUndefined(
        scheduleFilterRow
      )
        ? { ...scheduleFilterRow }
        : null;

      if (isNotNullAndUndefined(filterValues.AssigneeUID)) {
        assigneeFilterVal = filterValues.AssigneeUID;
        delete filterValues.AssigneeUID;
      }

      const scheduleFilter = convertScheduleFilterRowRAFCustomFilter(
        filterValues,
        relatedToUID
      );
      if (isNotEmptyArray(scheduleFilter)) {
        scheduleFilter.forEach((filter) => {
          customFilter.Rules.push(filter);
        });
      }
    }

    const filterStatusValue = isNotNullAndUndefined(scheduleFilterRow)
      ? scheduleFilterRow.Status
      : null;

    if (isNotEmptyArray(taskStatus) && filterStatusValue === "AllStatus") {
      let customTaskStatusFilter: RAFCustomFilter = {};
      customTaskStatusFilter.Condition = "or";
      customTaskStatusFilter.Rules = [];
      taskStatus.forEach((taskType) => {
        let taskStatusFilter: RAFCustomFilter = {};
        let taskStatusFilterVal: string[] = [];
        taskStatusFilterVal.push(taskType);
        taskStatusFilter.Operator = RAFCustomOperator.Equal;
        taskStatusFilter.Value = taskStatusFilterVal;
        taskStatusFilter.Field = propertyOf<ServiceTransactionRow>("Status");
        customTaskStatusFilter.Rules.push(taskStatusFilter);
      });
      customFilter.Rules.push(customTaskStatusFilter);
    }

    let sortBy = {
      field: propertyOf<ServiceTransactionRow>("StartDate"),
    };
    let sortQuery: string[] = [];
    if (isNotNullAndUndefined(sortBy) && isNotNullAndUndefined(sortBy.field)) {
      sortQuery.push(sortBy.field);
    }

    let listServiceRequest = new UserShiftSummaryListRequest();
    listServiceRequest.CustomFilter = customFilter;
    listServiceRequest.Take = take;
    listServiceRequest.Skip = skip;
    listServiceRequest.Sort = sortQuery;

    if (isNotNullAndUndefined(assigneeFilterVal)) {
      listServiceRequest.AssigneeUID = assigneeFilterVal;
    } else {
      if (showEmployeesWithoutShift !== true) {
        listServiceRequest.SummaryTabName = ShiftSummaryTabNames.WithRoster;
      }
    }

    let url = `${Constants.baseAPIUrl}ServiceTransaction/UserShiftDurationSummary`;

    return repositoryActions
      .postDataAndGetResponse(
        url,
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          const allUsers: RAFServiceTransactionDurationSummaryRow[] =
            response.data.Entities;

          resolve(allUsers);
        } else {
          resolve(null);
        }
      });
  });
};

export const getCareRecipientServiceTransactiontDurationSummary = (
  relatedToUID: string,
  skip: number = 0,
  take: number = 10,
  relatedFilters: RAFCustomFilter[],
  scheduleFilterRow: ScheduleFilterRow,
  viewName: "my_shift" | "all_shift",
  taskStatus: string[]
) => {
  return new Promise<RAFServiceTransactionDurationSummaryRow[]>((resolve) => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    if (viewName === "my_shift") {
      let assigneeFilter: RAFCustomFilter = {};
      let assigneeFilterVal: string[] = [];
      assigneeFilterVal.push(msalInstance.currentUserId);
      assigneeFilter.Operator = RAFCustomOperator.Equal;
      assigneeFilter.Value = assigneeFilterVal;
      assigneeFilter.Field = propertyOf<ServiceTransactionRow>("AssigneeUID");
      customFilter.Rules.push(assigneeFilter);
    }

    // if (isNotNullAndUndefined(relatedToUID)) {
    //   let relatedToFilter: RAFCustomFilter = {};
    //   let relatedToFilterVal: string[] = [];
    //   relatedToFilterVal.push(relatedToUID);
    //   relatedToFilter.Operator = RAFCustomOperator.Equal;
    //   relatedToFilter.Value = relatedToFilterVal;
    //   relatedToFilter.Field = propertyOf<ServiceTransactionRow>("RelatedToUID");
    //   customFilter.Rules.push(relatedToFilter);
    // }

    // if (isNotEmptyArray(taskTypes)) {
    //     let customTaskFilter: RAFCustomFilter = {};
    //     customTaskFilter.Condition = "or";
    //     customTaskFilter.Rules = [];
    //     taskTypes.forEach((taskType) => {
    //         let taskTypeFilter: RAFCustomFilter = {};
    //         let taskTypeFilterVal: string[] = [];
    //         taskTypeFilterVal.push(taskType);
    //         taskTypeFilter.Operator = RAFCustomOperator.Equal;
    //         taskTypeFilter.Value = taskTypeFilterVal;
    //         taskTypeFilter.Field = propertyOf<ServiceTransactionRow>("Type");
    //         customTaskFilter.Rules.push(taskTypeFilter);
    //     });
    //     customFilter.Rules.push(customTaskFilter);
    // }

    if (isNotEmptyArray(relatedFilters)) {
      relatedFilters.forEach((filter) => {
        customFilter.Rules.push(filter);
      });
    }

    const showClientsWithoutRoster = isNotNullAndUndefined(scheduleFilterRow)
      ? scheduleFilterRow.ShowClientsWithoutRoster
      : null;

    let clientUID = relatedToUID;
    if (isNotNullAndUndefined(scheduleFilterRow)) {
      const filterValues: ScheduleFilterRow = isNotNullAndUndefined(
        scheduleFilterRow
      )
        ? { ...scheduleFilterRow }
        : null;

      if (isNotNullAndUndefined(filterValues.RelatedToUID)) {
        clientUID = filterValues.RelatedToUID;
        delete filterValues.RelatedToUID;
      }

      const scheduleFilter = convertScheduleFilterRowRAFCustomFilter(
        filterValues,
        null
      );
      if (isNotEmptyArray(scheduleFilter)) {
        scheduleFilter.forEach((filter) => {
          customFilter.Rules.push(filter);
        });
      }
    }

    const filterStatusValue = isNotNullAndUndefined(scheduleFilterRow)
      ? scheduleFilterRow.Status
      : null;

    if (isNotEmptyArray(taskStatus) && filterStatusValue === "AllStatus") {
      let customTaskStatusFilter: RAFCustomFilter = {};
      customTaskStatusFilter.Condition = "or";
      customTaskStatusFilter.Rules = [];
      taskStatus.forEach((taskType) => {
        let taskStatusFilter: RAFCustomFilter = {};
        let taskStatusFilterVal: string[] = [];
        taskStatusFilterVal.push(taskType);
        taskStatusFilter.Operator = RAFCustomOperator.Equal;
        taskStatusFilter.Value = taskStatusFilterVal;
        taskStatusFilter.Field = propertyOf<ServiceTransactionRow>("Status");
        customTaskStatusFilter.Rules.push(taskStatusFilter);
      });
      customFilter.Rules.push(customTaskStatusFilter);
    }

    let sortBy = {
      field: propertyOf<ServiceTransactionRow>("StartDate"),
    };
    let sortQuery: string[] = [];
    if (isNotNullAndUndefined(sortBy) && isNotNullAndUndefined(sortBy.field)) {
      sortQuery.push(sortBy.field);
    }

    let listServiceRequest = new ClientShiftSummaryListRequest();
    listServiceRequest.CustomFilter = customFilter;
    listServiceRequest.Take = take;
    listServiceRequest.Skip = skip;
    listServiceRequest.Sort = sortQuery;
    if (isNotNullAndUndefined(clientUID)) {
      listServiceRequest.ClientUID = clientUID;
    } else {
      if (showClientsWithoutRoster !== true) {
        listServiceRequest.SummaryTabName = ShiftSummaryTabNames.WithRoster;
      }
    }

    let url = `${Constants.baseAPIUrl}ServiceTransaction/ClientShiftDurationSummary`;

    return repositoryActions
      .postDataAndGetResponse(
        url,
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          const allUsers: RAFServiceTransactionDurationSummaryRow[] =
            response.data.Entities;

          resolve(allUsers);
        } else {
          resolve(null);
        }
      });
  });
};

export function getInvoiceTransactionByShiftId(id) {
  let invoiceTransactionRequest = new InvoiceTransactionRequest();
  invoiceTransactionRequest.ShiftUID = id;
  return new Promise<InvoiceTransactionRow[]>((resolve) => {
    repositoryActions
      .postDataAndGetResponse(
        "InvoiceTransaction/ListByShift",
        invoiceTransactionRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          const allTransactions: InvoiceTransactionRow[] =
            response.data.Entities;
          if (isNotEmptyArray(allTransactions)) {
            resolve(allTransactions);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

export function getPayrollTransactionByShiftId(id) {
  let invoiceTransactionRequest = new InvoiceTransactionRequest();
  invoiceTransactionRequest.ShiftUID = id;
  return new Promise<PayrollTransactionRow[]>((resolve) => {
    repositoryActions
      .postDataAndGetResponse(
        "PayrollTransaction/ListByShift",
        invoiceTransactionRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          const allTransactions: PayrollTransactionRow[] =
            response.data.Entities;
          if (isNotEmptyArray(allTransactions)) {
            resolve(allTransactions);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

//PayrollTransaction

export function getPayrollInvoiceTransactionByClientUID(
  clientUID: string,
  moduleName:
    | TimeSheetSummaryModuleName.InvoiceTransaction
    | TimeSheetSummaryModuleName.PayrollTransaction,
  customFilter: RAFCustomFilter
) {
  return new Promise<InvoiceTransactionRow[]>((resolve) => {
    if (isNotNullAndUndefined(clientUID)) {
      let invoiceTransactionClientRequest =
        new InvoiceTransactionClientRequest();
      invoiceTransactionClientRequest.ClientUID = clientUID;

      if (isNotNullAndUndefined(customFilter)) {
        invoiceTransactionClientRequest["CustomFilter"] = customFilter;
      }

      const url =
        moduleName === TimeSheetSummaryModuleName.PayrollTransaction
          ? "PayrollTransaction/ListByClient"
          : "InvoiceTransaction/ListByClient";

      repositoryActions
        .postDataAndGetResponse(
          url,
          invoiceTransactionClientRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            const allTransactions: InvoiceTransactionRow[] =
              response.data.Entities;
            if (isNotEmptyArray(allTransactions)) {
              resolve(allTransactions);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => resolve(null));
    } else {
      resolve(null);
    }
  });
}

function getDisplayDateText(scheduledTime: Date) {
  const currentTime = moment();
  const scheduled = moment(scheduledTime);

  if (currentTime.format("YYYY-MM-DD") === scheduled.format("YYYY-MM-DD")) {
    // same day, display time
    return scheduled.format("hh:mm A");
  } else {
    // different day, display date
    return scheduled.format("DD-MM-YYYY hh:mm A");
  }
}

export const checkHasPermissionToAddOrEditActivitylog = (
  tenantSettings: TenantSettingsRow,
  selectedServiceTransactionRow: ServiceTransactionRow
) => {
  let hasPermissionToEdit = true;
  let warningMessage = "";

  if (
    isNotNullAndUndefined(selectedServiceTransactionRow) &&
    isNotNullAndUndefined(tenantSettings)
  ) {
    const shiftActualStartTime = selectedServiceTransactionRow.ActualStartDate;
    const shiftDuration = selectedServiceTransactionRow.Duration;

    const editShiftlogMaxDuration = tenantSettings.EditShiftlogMaxDuration;

    if (
      isNotNullAndUndefined(editShiftlogMaxDuration) &&
      editShiftlogMaxDuration > 0 &&
      isNotNullAndUndefined(shiftActualStartTime) &&
      isNotNullAndUndefined(shiftDuration)
    ) {
      //allow adding or editing shift activity  if current time <= shiftStartTime+shiftDuration +editShiftlogMaxDuration
      const currentTime = new Date();
      const shiftEndTime = moment(shiftActualStartTime)
        .add(shiftDuration, "minutes")
        .toDate();
      const shiftEndTimePlusMaxDuration = moment(shiftEndTime)
        .add(editShiftlogMaxDuration, "minutes")
        .toDate();

      if (currentTime > shiftEndTimePlusMaxDuration) {
        warningMessage = `Unfortunately, you cannot add or edit activity log for this shift as it was scheduled to end at ${getDisplayDateText(
          shiftEndTime
        )}. The maximum allowable delay is ${getDurationDisplayText(
          editShiftlogMaxDuration
        )}.`;
        hasPermissionToEdit = false;
      }
    }
  }

  const response = {
    hasPermissionToEdit: hasPermissionToEdit,
    warningMessage: warningMessage,
  };
  return response;
};

export const checkHasPermissionToStartOrCompleteShift = (
  tenantSettings: TenantSettingsRow,
  startEndDateTime: Date,
  taskAction: "Clock In" | "Clock Out"
) => {
  let hasPermissionToEdit = true;
  let warningMessage = "";
  if (taskAction === "Clock Out") {
    const response = {
      hasPermissionToEdit: hasPermissionToEdit,
      warningMessage: warningMessage,
    };

    return response;
  } else {
    if (
      isNotNullAndUndefined(startEndDateTime) &&
      isNotNullAndUndefined(taskAction) &&
      isNotNullAndUndefined(tenantSettings)
    ) {
      const maxStartDelayDurationInMinutes = tenantSettings.MaxStartDelay;

      const action = "start";

      if (
        isNotNullAndUndefined(maxStartDelayDurationInMinutes) &&
        maxStartDelayDurationInMinutes > 0
      ) {
        const currentTime = new Date();

        const scheduledTimeMinusMaxDelay = moment(startEndDateTime)
          .subtract(maxStartDelayDurationInMinutes, "minutes")
          .toDate();

        if (currentTime < scheduledTimeMinusMaxDelay) {
          const validStartTime = moment(startEndDateTime)
            .subtract(maxStartDelayDurationInMinutes, "minutes")
            .toDate();

          warningMessage = `Unfortunately, you cannot ${action} this shift as it was scheduled to ${action} 
          at ${getDisplayDateText(startEndDateTime)}. 
          The maximum allowable delay is ${getDurationDisplayText(
            maxStartDelayDurationInMinutes
          )}.
           You can start the shift at ${getDisplayDateText(validStartTime)}.`;

          hasPermissionToEdit = false;
        }
      }
    }
    const response = {
      hasPermissionToEdit: hasPermissionToEdit,
      warningMessage: warningMessage,
    };

    return response;
  }
};

export const checkHasPermissionToStartOrCompleteShift1 = (
  tenantSettings: TenantSettingsRow,
  startEndDateTime: Date,
  taskAction: "Clock In" | "Clock Out"
) => {
  let hasPermissionToEdit = true;
  let warningMessage = "";
  if (
    isNotNullAndUndefined(startEndDateTime) &&
    isNotNullAndUndefined(taskAction) &&
    isNotNullAndUndefined(tenantSettings)
  ) {
    const maxDelayDurationInMinutes =
      taskAction === "Clock In" ? tenantSettings.MaxStartDelay : 0; // tenantSettings.MaxEndDelay;//no need to use now
    const action = taskAction === "Clock In" ? "start" : "end";

    if (
      isNotNullAndUndefined(maxDelayDurationInMinutes) &&
      maxDelayDurationInMinutes > 0
    ) {
      const currentTime = new Date();
      const scheduledTimePlusMaxDelay = moment(startEndDateTime)
        .add(maxDelayDurationInMinutes, "minutes")
        .toDate();

      if (currentTime > scheduledTimePlusMaxDelay) {
        warningMessage = `Unfortunately, you cannot ${action} this shift as it was scheduled to ${action} at ${getDisplayDateText(
          startEndDateTime
        )}. The maximum allowable delay is ${getDurationDisplayText(
          maxDelayDurationInMinutes
        )}.`;
        hasPermissionToEdit = false;
      }
    }
  }
  const response = {
    hasPermissionToEdit: hasPermissionToEdit,
    warningMessage: warningMessage,
  };

  return response;
};

export const checkHasPermissionToEditShift = (
  tenantSettings: TenantSettingsRow,
  selectedServiceTransactionRow: ServiceTransactionRow,
  permissionValue: RolePermissionRow,
  permissionName: string
) => {
  let hasPermissionToEdit = true;
  let warningMessage = "";
  if (isNotNullAndUndefined(selectedServiceTransactionRow)) {
    if (
      selectedServiceTransactionRow.Status === RAFShiftActionStatus.Completed
    ) {
      const allowshifttimechange = isNotNullAndUndefined(tenantSettings)
        ? tenantSettings.Allowshifttimechange === "Yes"
        : false;
      if (allowshifttimechange) {
        const editMaxDuration = isNotNullAndUndefined(tenantSettings)
          ? tenantSettings.EditShiftMaxDuration
          : 0;
        const actualEndDate = selectedServiceTransactionRow.EndDate;
        const currentTime = new Date();
        const completedBefore = getTimeDifferenceInMinutes(
          actualEndDate,
          currentTime
        );
        if (
          isNotNullAndUndefined(editMaxDuration) &&
          isNotNullAndUndefined(completedBefore) &&
          editMaxDuration > 0
        ) {
          if (completedBefore <= editMaxDuration) {
            hasPermissionToEdit = true;
          } else {
            warningMessage = `Apologies, you cannot request a time change as the shift was completed ${getDurationDisplayText(
              completedBefore
            )} ago. Time change requests can only be made within ${getDurationDisplayText(
              editMaxDuration
            )} after shift completion.`;
            //warningMessage = `Apologies, you are not allowed to edit this record as the shift was completed before ${getDurationDisplayText(completedBefore)} minutes.`;
            hasPermissionToEdit = false;
          }
        } else {
          warningMessage = `You do not have permission to edit this record`;
          hasPermissionToEdit = false;
        }
      } else {
        warningMessage = `You do not have permission to edit this record`;
        hasPermissionToEdit = false;
      }
    }
  }

  if (hasPermissionToEdit === true) {
    if (hasPermission(permissionValue, permissionName) === false) {
      warningMessage = `You do not have permission to request a time change for this shift`;
      hasPermissionToEdit = false;
    }
  }

  const response = {
    hasPermissionToEdit: hasPermissionToEdit,
    warningMessage: warningMessage,
  };

  return response;
};

export const getScheduleFilterFromSessionStoreageRow = (
  currentViewName: string,
  relatedToUID: string
) => {
  const sessionStorageKey = `${RAFEntityName.ServiceTransaction}_${currentViewName ?? relatedToUID
    }`;
  const serviceTransactionDefaultShiftFilterStoreage = JSON.parse(
    getSessionStorage(sessionStorageKey, true)
  );
  const scheduleFilterRow: ScheduleFilterRow = isNotNullAndUndefined(
    serviceTransactionDefaultShiftFilterStoreage
  )
    ? serviceTransactionDefaultShiftFilterStoreage.scheduleFilterRow
    : null;

  if (isNotNullAndUndefined(scheduleFilterRow)) {
    if (scheduleFilterRow.SelectedDate) {
      scheduleFilterRow.SelectedDate = new Date(scheduleFilterRow.SelectedDate);
    }

    if (scheduleFilterRow.StartDate) {
      scheduleFilterRow.StartDate = new Date(scheduleFilterRow.StartDate);
    }
    if (scheduleFilterRow.EndDate) {
      scheduleFilterRow.EndDate = new Date(scheduleFilterRow.EndDate);
    }
  }

  return scheduleFilterRow;
};

export const getScheduleFilterDisplayStyleFromSessionStoreageRow = (
  currentViewName: string,
  relatedToUID: string
) => {
  const sessionStorageKey = `${RAFEntityName.ServiceTransaction}_${currentViewName ?? relatedToUID
    }`;
  const serviceTransactionDefaultShiftFilterStoreage = JSON.parse(
    getSessionStorage(sessionStorageKey, true)
  );
  const displayStyle = isNotNullAndUndefined(
    serviceTransactionDefaultShiftFilterStoreage
  )
    ? serviceTransactionDefaultShiftFilterStoreage.displayStyle
    : RAFGridViewDisplayname.ListView;

  return displayStyle;
};

const toDisplayEmployeeAvailableResponseRow = (
  employeeAvailableResponseRow: EmployeeAvailableResponseRow[]
) => {
  if (isNotEmptyArray(employeeAvailableResponseRow)) {
    const output: EmployeeAvailableDisplayResponseRow[] =
      employeeAvailableResponseRow.reduce((acc, item) => {
        const existing = acc.find((i) => i.EmployeeUID === item.EmployeeUID);
        if (existing) {
          existing.EmployeeInfo.push({
            StartDate: item.StartDate,
            EndDate: item.EndDate,
            AvailabilityType: item.AvailabilityType,
          });
        } else {
          acc.push({
            EmployeeUID: item.EmployeeUID,
            EmployeeName: item.EmployeeName,
            EmployeeInfo: [
              {
                StartDate: item.StartDate,
                EndDate: item.EndDate,
                AvailabilityType: item.AvailabilityType,
              },
            ],
          });
        }
        return acc;
      }, []);

    return output;
  } else {
    return [];
  }
};

export const getAssigneeCardContentListItems = (
  showEmployeeCareTeam: string,
  showEmployeeCareTeamOnlyAvailable: boolean,
  clientUID: string,
  startDate: Date,
  endDate: Date
) => {
  return new Promise<AssigneeCardContentListItemRow>(async (resolve) => {
    if (showEmployeeCareTeam === CareEsioEntity.Employee.EntityName) {
      if (showEmployeeCareTeamOnlyAvailable === true) {
        const employeeAvailability = await getEmployeeAvailability(
          startDate,
          endDate
        );
        const response: AssigneeCardContentListItemRow = {
          employeeAvailability:
            toDisplayEmployeeAvailableResponseRow(employeeAvailability),
          allEmployeeLookUpList: null,
          careTeamAvailability: null,
          allCareTeamList: null,
        };
        resolve(response);
      } else {
        const allEmployeeLookUpList = await getLookUpItems(
          "User/EmployeeLookup",
          0,
          500,
          null,
          CareEsioEntity.Employee.EntityName
        );
        const response: AssigneeCardContentListItemRow = {
          employeeAvailability: null,
          allEmployeeLookUpList: allEmployeeLookUpList,
          careTeamAvailability: null,
          allCareTeamList: null,
        };
        resolve(response);
      }
    } else if (showEmployeeCareTeam === CareEsioEntity.CareTeam.EntityName) {
      if (showEmployeeCareTeamOnlyAvailable === true) {
        const careTeamAvailability = await getEmployeeCareTeamAvailability(
          clientUID,
          startDate,
          endDate,
          true
        );
        const response: AssigneeCardContentListItemRow = {
          employeeAvailability: null,
          allEmployeeLookUpList: null,
          careTeamAvailability:
            toDisplayEmployeeAvailableResponseRow(careTeamAvailability),
          allCareTeamList: null,
        };
        resolve(response);
      } else {
        const allCareTeamList: {
          StartDate?: Date;
          EndDate?: Date;
          EmployeeUID?: string;
          EmployeeName?: string;
          AvailabilityType?: string;
        }[] = await getEmployeeCareTeamAvailability(
          clientUID,
          startDate,
          endDate,
          null
        );

        const response: AssigneeCardContentListItemRow = {
          employeeAvailability: null,
          allEmployeeLookUpList: null,
          careTeamAvailability: null,
          allCareTeamList:
            toDisplayEmployeeAvailableResponseRow(allCareTeamList),
        };
        resolve(response);
      }
    } else {
      resolve(null);
    }
  });
};
