import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, { PropsWithChildren, Reducer, Suspense, useEffect, useMemo, useReducer } from "react";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import RAFFieldStateProvider from "../../../../RAFComponents/Grid/RAFFieldStateProvider";
import RAFEmptyState from "../../../../RAFComponents/Navigation/RAFEmptyState";
import RAFAttributeRelatedListProvider from "../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { RAFCustomFilter, RAFCustomOperator } from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import RAFLoadingPanel from "../../../../RAFComponents/helpers/RAFLoadingPanel";
import { getDisplayNameByModuleName } from "../../../../RAFComponents/helpers/RAFMenuHelper";
import { IsNullOrWhiteSpace, isNotEmptyArray, isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { Constants, RAFReportType, RAFTreeViewMenu, rafNoViewName } from "../../../../constants/Common/Constants";
import RAFGridTemplates, { getGridColumnTemplatesByModule } from "../../../../helpers/RAFGridTemplates";
import UpdateInvoiceShiftItem from "../../../CareESIO/CareShiftLog/CareBillingTimesheet/CareBilling/UpdateInvoiceShiftItem";
import UpdatePayrollShiftItem from "../../../CareESIO/CareShiftLog/CareBillingTimesheet/TimeSheet/UpdatePayrollShiftItem";
import { getViewElementId, getViewsReportsDashboards } from "../../../Common/List/IndexHelper";
import RAFEntityProvider, { RAFEntityContext } from "../../../Common/Providers/RAFEntityProvider";
import { TimeSheetSummaryDetailsArray, TimeSheetSummaryModuleName, getDefaultGridColumnsInvoicePayroll } from "./ServiceTimesheetSummaryHelper";
import RAFGrid4 from "../../../../RAFComponents/Grid/RAFGrid4";
//const RAFGrid4 = React.lazy(() => import('../../../../RAFComponents/Grid/RAFGrid4'));

interface IProps {
    isActive: boolean,
    selectedTimeSheetSummaryItem: TimeSheetSummaryDetailsArray;
}

interface IState {
    isActive: boolean,
    isLoading: boolean;
    showEditDialog: boolean;
    selectedRow: any;
    currentView: RAFTreeViewMenu;
    gridOuterDivKey: number;
}

function ServiceTimesheetSummaryGridContent({ selectedTimeSheetSummaryItem, ...props }: PropsWithChildren<IProps>) {

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isActive: false,
            isLoading: true,
            currentView: null,
            showEditDialog: false,
            selectedRow: null,
            gridOuterDivKey: Math.random()
        }
    );

    useEffect(() => {
        refresh();
    }, [selectedTimeSheetSummaryItem, props.isActive]);

    const refresh = async () => {
        if (props.isActive && isNotNullAndUndefined(selectedTimeSheetSummaryItem)) {
            setState({ isActive: true, isLoading: true, currentView: null, showEditDialog: false, selectedRow: null });
            const allViews = await getViewsReportsDashboards(selectedTimeSheetSummaryItem.moduleName, true, true);
            const viewMenuDataSource = isNotEmptyArray(allViews) ? allViews.filter(x => x.Category === RAFReportType.Table) : [];

            const currentView = isNotNullAndUndefined(viewMenuDataSource) ? viewMenuDataSource[0] : null;
            setState({ isLoading: false, currentView });
        } else {
            setState({ isActive: false, isLoading: false, currentView: null });
        }
    };

    function rowClick(itemRow) {
        if (isNotNullAndUndefined(itemRow)) {
            setState({ showEditDialog: true, selectedRow: itemRow });
        }
    }

    // Update Dialog start

    const updateContent = () => {
        if (state.showEditDialog) {
            if (selectedTimeSheetSummaryItem.moduleName === TimeSheetSummaryModuleName.InvoiceTransaction) {
                return (
                    <UpdateInvoiceShiftItem
                        objectUID={state.selectedRow.UID}
                        onSave={onUpdate}
                        onClose={closeUpdateDialog}
                        isActive
                    />
                );
            } else {
                return (
                    <UpdatePayrollShiftItem
                        objectUID={state.selectedRow.UID}
                        onSave={onUpdate}
                        onClose={closeUpdateDialog}
                        isActive
                    />
                );
            }
        } else {
            return null;
        }
    };

    const onUpdate = () => {
        setState({ showEditDialog: false, selectedRow: null, gridOuterDivKey: Math.random() });
    };

    const closeUpdateDialog = () => {
        setState({ showEditDialog: false, selectedRow: null });
    };

    // Update Dialog end

    const getAdditionalListFilter = (selectedTimeSheetSummaryItem: TimeSheetSummaryDetailsArray) => {
        let additionalListFilter: RAFCustomFilter = {};
        additionalListFilter.Condition = "and";
        additionalListFilter.Rules = [];

        if (isNotNullAndUndefined(selectedTimeSheetSummaryItem.fieldProperty) && isNotNullAndUndefined(selectedTimeSheetSummaryItem.fieldStatus)) {
            let filter: RAFCustomFilter = {};
            let filterValue: string[] = [];
            filterValue.push(selectedTimeSheetSummaryItem.fieldStatus);
            filter.Operator = RAFCustomOperator.Equal;
            filter.Value = filterValue;
            filter.Field = selectedTimeSheetSummaryItem.fieldProperty;
            additionalListFilter.Rules.push(filter);
        };
        return additionalListFilter;
    };

    const getAdditionalParams = () => {
        const additionalParams = [
            {
                key: isNotNullAndUndefined(selectedTimeSheetSummaryItem.shiftUID) ? 'ShiftUID' : "ClientUID",
                value: isNotNullAndUndefined(selectedTimeSheetSummaryItem.shiftUID) ? selectedTimeSheetSummaryItem.shiftUID : selectedTimeSheetSummaryItem.careRecipientUID,
            },
        ];
        return additionalParams;
    };


    const getGridContent = useMemo(() => {
        const moduleName = selectedTimeSheetSummaryItem.moduleName;
        const lookUpGridId = `grid3_${moduleName}_realted_lookUpList`;

        const url = selectedTimeSheetSummaryItem.url;

        return (
            <div className="px-0 hover-table-div h-100">
                <Suspense fallback={<div>Loading...</div>}>
                    <RAFFieldStateProvider
                        moduleName={moduleName}
                    //viewId={currentView.UID}
                    >
                        <RAFGridTemplates>
                            <RAFGrid4
                                url={`${Constants.baseAPIUrl}${url}`}
                                gridId={lookUpGridId}
                                moduleName={moduleName}
                                isRemote
                                rowClick={rowClick}
                                showEditColumn={false}
                                allowEditing={false}
                                allowSelection={false}
                                allowFiltering={false}
                                gridTemplates={getGridColumnTemplatesByModule(
                                    moduleName
                                )}
                                disableFirstColumnTemplate
                                emptyStateProps={{
                                    title: "No results found.",
                                    body: "Try adjusting your search or changing your filter to find what you're looking for!",
                                }}
                                //viewId={currentView.UID}
                                filterBarVisibile={false}
                                additionalFilter={getAdditionalListFilter(selectedTimeSheetSummaryItem)}
                                additionalParams={getAdditionalParams()}
                                {...(isNotNullAndUndefined(moduleName)
                                    ? { isDynamic: true }
                                    : {})}
                                //filterType="QueryBuilder"
                                isAdaptive={false}
                            >
                                {getDefaultGridColumnsInvoicePayroll(moduleName)}
                            </RAFGrid4>
                        </RAFGridTemplates>
                    </RAFFieldStateProvider>
                </Suspense>
            </div>
        );
    }, [selectedTimeSheetSummaryItem]);

    const getBodyContent = () => {
        if (state.isLoading === false) {
            const { currentView } = state;
            if (
                isNotNullAndUndefined(currentView) &&
                (isNotNullAndUndefined(currentView.UID) ||
                    (IsNullOrWhiteSpace(currentView.UID) &&
                        currentView.Name !== rafNoViewName)) &&
                isNotNullAndUndefined(currentView.Category)
            ) {
                const moduleName = selectedTimeSheetSummaryItem.moduleName;

                return (
                    <div className="e-card-content h-100">
                        <RAFAttributeRelatedListProvider moduleName={moduleName}>
                            <RAFEntityProvider moduleName={moduleName}>
                                <RAFEntityContext.Consumer>
                                    {({ entity }) => {
                                        return (
                                            <div
                                                className="col-12 p-0 h-100"
                                                key={`${getViewElementId(currentView.Name, currentView.UID)}_${state.gridOuterDivKey}`}
                                            >
                                                {currentView.Category === RAFReportType.Table && (
                                                    getGridContent
                                                )}
                                                <div>
                                                    {state.showEditDialog && (
                                                        <DialogComponent
                                                            header={
                                                                "Update " +
                                                                getDisplayNameByModuleName(moduleName, entity.DisplayName)
                                                                //ConvertToPascal(getDisplayNameByModuleName(props.moduleName))
                                                            }
                                                            showCloseIcon
                                                            visible={state.showEditDialog}
                                                            content={updateContent.bind(this)}
                                                            //cssClass={entity.EntityType === RAFEntityType.UserForm ? "dlg-new-style centerDialog-lg createEditForm form-center-dialog" : "rightDialog createEditForm full-height form-center-dialog"}
                                                            cssClass={
                                                                "rightDialog createEditForm full-height form-center-dialog dlg-new-style"
                                                            }
                                                            isModal
                                                            target="body"
                                                            closeOnEscape={false}
                                                            close={closeUpdateDialog.bind(this)}
                                                            id={`dlg_create_${moduleName}`}
                                                            zIndex={1200}
                                                            open={PreventFocusOnDialogOpen}
                                                        ></DialogComponent>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }}
                                </RAFEntityContext.Consumer>
                            </RAFEntityProvider>
                        </RAFAttributeRelatedListProvider>
                    </div >
                );
            } else {
                return (
                    <div className="pb-sm-3 h-100">
                        {isNotNullAndUndefined(currentView) && (
                            <RAFEmptyState
                                body="No view found. Add a view under this category"
                                title="No results found."
                            />
                        )}
                    </div>
                );
            }
        } else {
            return (
                <div className='container-fluid px-0'>
                    <RAFLoadingPanel loadingText='Preparing Data...' />
                </div>
            );
        }
    };

    if (state.isActive) {
        return (
            getBodyContent()
        );
    } else {
        return (
            <div className="pb-sm-3 h-100">
                <RAFEmptyState
                    body="No view found. Add a view under this category"
                    title="No results found."
                />
            </div>
        );
    }
}

export default React.memo(ServiceTimesheetSummaryGridContent);