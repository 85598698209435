import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, { PropsWithChildren, Reducer, useReducer } from "react";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import { IsNotNullOrWhiteSpace, hexToRGBA, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { RAFButtonConstant, RAFShiftActionStatus, RAFStatusNameWithColor } from "../../../constants/Common/Constants";
import ApproveRejectTimeChangeRequestStatus from "../../CareESIO/TimeChangeRequest/ApproveRejectTimeChangeRequestStatus";
import ManageTimeChangeRequest from "../../CareESIO/TimeChangeRequest/ManageTimeChangeRequest";
import { ServiceTransactionRow } from "./ServiceTransactionRow";

interface IProps {
    showlabel: boolean,
    labelClassName: string,
    serviceTransactionRow: ServiceTransactionRow;
    onUpdateServiceTransaction?: () => void;
}

interface IState {
    showRequestTimeChangeDlg: boolean;
    showUpdateTimeChangeRequestDlg: boolean;
}

function ServiceTransactionTimeChangeRequestContent({
    serviceTransactionRow,
    labelClassName,
    showlabel = true,
    ...props
}: PropsWithChildren<IProps>) {
    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            showRequestTimeChangeDlg: false,
            showUpdateTimeChangeRequestDlg: false,
        }
    );

    //update time change request start
    const onClickUpdateTimeChangeRequest = () => {
        const timeChangeRequestStatus = serviceTransactionRow.TimeChangeRequestStatus;
        if (timeChangeRequestStatus === RAFShiftActionStatus.AwaitingForApproval) {
            setState({ showUpdateTimeChangeRequestDlg: true });
        } else {
            showRequestTimeChangeDlg();
        }
    };

    const updateRequestTimeChangeContent = () => {
        if (state.showUpdateTimeChangeRequestDlg) {
            return (
                <ApproveRejectTimeChangeRequestStatus
                    serviceTransactionUID={serviceTransactionRow.UID}
                    actualStartDate={serviceTransactionRow.ActualStartDate}
                    actualEndDate={serviceTransactionRow.ActualEndDate}
                    onClose={updateTimeChangeRequestDialogClose}
                    onSave={onupdateRequestTimeChangeStatus}
                />
            );
        } else {
            return null;
        }
    };

    const onupdateRequestTimeChangeStatus = () => {
        setState({ showUpdateTimeChangeRequestDlg: false });
        if (props.onUpdateServiceTransaction) {
            props.onUpdateServiceTransaction();
        }
    };

    const updateTimeChangeRequestDialogClose = () => {
        setState({ showUpdateTimeChangeRequestDlg: false });
    };
    //update time change request end

    //request time change start
    const showRequestTimeChangeDlg = () => {
        setState({ showRequestTimeChangeDlg: true });
    };

    const requestTimeChangeContent = () => {
        if (state.showRequestTimeChangeDlg) {
            return (
                <ManageTimeChangeRequest
                    serviceTransactionRow={serviceTransactionRow}
                    onClose={requestTimeChangeDialogClose}
                    onSave={onSaveCompletedServiceTransaction}
                />
            );
        } else {
            return null;
        }
    };

    const requestTimeChangeDialogClose = () => {
        setState({ showRequestTimeChangeDlg: false });
    };

    const onSaveCompletedServiceTransaction = () => {
        setState({ showRequestTimeChangeDlg: false });
        if (props.onUpdateServiceTransaction) {
            props.onUpdateServiceTransaction();
        }
    };
    //request time change end

    if (isNotNullAndUndefined(serviceTransactionRow)) {

        const timeChangeRequestStatusNameWithColor =
            isNotNullAndUndefined(serviceTransactionRow.TimeChangeRequestStatus) &&
                isNotNullAndUndefined(
                    RAFStatusNameWithColor[serviceTransactionRow.TimeChangeRequestStatus]
                )
                ? RAFStatusNameWithColor[serviceTransactionRow.TimeChangeRequestStatus]
                : null;

        return (
            <div>
                {showlabel && (
          <div
            className={`${labelClassName ? labelClassName : "subtitle_3 medium"
              } mb-1 ecllipseFirstLine`}
          >
            {CareEsioEntity.TimeChangeRequest.DisplayName}
          </div>
        )}
        {serviceTransactionRow.Status === RAFShiftActionStatus.Completed ? (
          <div className="d-flex align-items-center pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  onClickUpdateTimeChangeRequest();
                }}
                >
            {isNotNullAndUndefined(serviceTransactionRow.TimeChangeRequestStatus) ? (
              <div
                className="pointer d-flex align-items-center"
              >
                <span
                  className="raf_badge raf_sm text-wrap word-break-all ecllipseFirstLine"
                  style={{
                    backgroundColor: isNotNullAndUndefined(
                      timeChangeRequestStatusNameWithColor
                    )
                      ? hexToRGBA(
                        timeChangeRequestStatusNameWithColor.Color,
                        0.1
                      )
                      : "#333",
                    color: isNotNullAndUndefined(
                      timeChangeRequestStatusNameWithColor
                    )
                      ? timeChangeRequestStatusNameWithColor.Color
                      : null,
                    border: `1px solid ${hexToRGBA(
                      isNotNullAndUndefined(
                        timeChangeRequestStatusNameWithColor
                      )
                        ? timeChangeRequestStatusNameWithColor.Color
                        : null,
                      0.5
                    )}`,
                  }}
                >
                  {IsNotNullOrWhiteSpace(timeChangeRequestStatusNameWithColor)
                    ? timeChangeRequestStatusNameWithColor.DisplayName
                    : IsNotNullOrWhiteSpace(serviceTransactionRow.TimeChangeRequestStatus)
                      ? serviceTransactionRow.TimeChangeRequestStatus
                      : "TimeChangeRequestStatus"}
                </span>
                <span className="ms-2">
                  <i className="fasl fa-pencil"></i>
                </span>
              </div>
            ) : (
              <RAFButtonComponent
                action={RAFButtonConstant.Save}
                btnContent="Request"
                iconCss="fas fa-clock"
                className="new_style link-button raf_sm h-auto"   
                showIcon={true}
              ></RAFButtonComponent>
            )}
          </div>
        ) : (
          <div className="details-value">NA</div>
        )}
                <>
                    {state.showUpdateTimeChangeRequestDlg && (
                        <DialogComponent
                            header={`Approve ${CareEsioEntity.TimeChangeRequest.DisplayName}`}
                            showCloseIcon
                            visible={state.showUpdateTimeChangeRequestDlg}
                            cssClass="centerDialog-md createEditForm full-height form-center-dialog dlg-new-style fixed-header"
                            content={updateRequestTimeChangeContent.bind(this)}
                            isModal
                            target="body"
                            closeOnEscape={false}
                            close={updateTimeChangeRequestDialogClose.bind(this)}
                            zIndex={1300}
                            open={PreventFocusOnDialogOpen}
                        />
                    )}
                    {state.showRequestTimeChangeDlg && (
                        <DialogComponent
                            header={CareEsioEntity.TimeChangeRequest.DisplayName}
                            showCloseIcon
                            visible={state.showRequestTimeChangeDlg}
                            cssClass="centerDialog-md createEditForm full-height form-center-dialog dlg-new-style fixed-header"
                            content={requestTimeChangeContent.bind(this)}
                            isModal
                            target="body"
                            closeOnEscape={false}
                            close={requestTimeChangeDialogClose.bind(this)}
                            zIndex={1300}
                            open={PreventFocusOnDialogOpen}
                        />
                    )}
                </>
            </div>
        );
    } else {
        return <></>;
    }
}

export default React.memo(ServiceTransactionTimeChangeRequestContent);