import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer
} from "react";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import { isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { ShiftSummaryDetailsArray, getDefaultShiftSummaryDetailsArray, getShiftSummaryDetailsArray } from "./ServiceTransactionSummaryHelper";

interface IProps {
  careRecipientRow: any;
  careRecipientUID: string;
  careRecipientName: string;
  onServiceTransactionClicked: (selectedItem: ShiftSummaryDetailsArray) => void;
}

interface IState {
  shiftSummaryDetailsArray: ShiftSummaryDetailsArray[];
}

function ServiceTransactionSummary({
  careRecipientUID,
  careRecipientName,
  ...props
}: PropsWithChildren<IProps>) {

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      shiftSummaryDetailsArray: getDefaultShiftSummaryDetailsArray(),
    }
  );

  useEffect(() => {
    refresh();
  }, [careRecipientUID]);

  const refresh = async () => {
    const shiftSummaryDetailsArray = await getShiftSummaryDetailsArray(careRecipientUID);

    setState({
      shiftSummaryDetailsArray
    });
  };

  const showShiftSummaryDetailsContent = (selectedItem: ShiftSummaryDetailsArray) => {
    if (isNotNullAndUndefined(props.onServiceTransactionClicked)) {
      props.onServiceTransactionClicked(selectedItem);
    }
  };

  return (
    <div>
      <div className="row g-2 g-md-3">
        {state.shiftSummaryDetailsArray.map((item, index) => {
          return (
            <div className="col-6 col-xl-3" key={index}>
              <CustomCardWidget
                onClick={() => showShiftSummaryDetailsContent(item)}
                cardClassName="overflow-hidden pointer"
                cardContentClassName="p-2-5"
                headerTemplate={
                  <div className={`d-flex align-items-center justify-content-between p-2-5 surface_brand_${item.themeName}_base`}>
                    <div>
                      <i className={`${item.iconCss} fa-md content_brand_${item.themeName}_base`}></i>
                    </div>
                    {isNotNullAndUndefined(item.body) ?
                      <div>
                        <span className="body_3">{item.body}</span>
                      </div>
                      :
                      ''
                    }
                  </div>
                }
              >
                <div className="subtitle_1 extra_bold">{item.count}</div>
                <span className="body_2">{item.title}</span>
              </CustomCardWidget>
            </div>);
        }
        )}
      </div>
    </div>
  );
}
export default React.memo(ServiceTransactionSummary);
