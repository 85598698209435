import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { RAFEntityName } from '../../../constants/Common/EntityConstants';

export class IServiceTransactionRow {
    UID?: string;
    Title?: string;
    StartDate?: Date;
    EndDate?: Date;
    ActualStartDate?: Date;
    ActualEndDate?: Date;
    RecordedStartDate?: Date;
    RecordedEndDate?: Date;
    Duration?: number;
    ActualDuration?: number;
    Type?: string;
    Status?: string;
    BillingStatus?: string;
    TimesheetStatus?: string;
    AssigneeUID?: string;
    Assignee?: string;
    AssigneeType?: string;
    RelatedTo?: string;
    RelatedToUID?: string;
    RelatedToType?: string;
    AssignTeam?: string;
    AssignTeamUID?: string;
    Priority?: string;
    SortOrder?: number;
    Description?: string;
    CommentCount?: number;
    SecondaryRelatedToUID?: string;
    SecondaryRelatedToType?: string;
    SecondaryRelatedTo?: string;
    ParentUID?: string;
    Parent?: string;
    //ChildCount?: number;
    ServiceTransactionIdNumber?: number;
    ServiceTransactionId?: string;
    CompletedByUID?: string;
    CompletedBy?: string;
    RateType?: string;
    Rate?: number;
    LineItemNameUID?: string;
    LineItemName?: string;
    PayLoadingRate?: number;
    PayLoadingUID?: string;
    PayLoading?: string;
    BasicPay?: number;
    InvoiceAmount?: number;
    PayrollAmount?: number;
    InvoiceUID?: string;
    Invoice?: string;
    PayrollUID?: string;
    Payroll?: string;
    Frequency?: string;
    CronExpression?: string;
    RRuleExpression?: string;
    TagsListJson?: string[];
    Portal?: string;
    PortalUID?: string;
    Total?: number;
    Completed?: number;
    IndependenceLevel?: string;
    IndependenceLevelScore?: number;
    ServiceContractItem?: string;
    ServiceContractItemUID?: string;
    Variant?: string;
    VariantUID?: string;
    ServiceName?: string;
    ServiceCode?: string;
    Latitude?: string;
    Longitude?: string;
    TravelVariant?: string;
    TravelVariantUID?: string;
    State?: string;
    GroupUID?: string;
    TravelDistance?: number;
    EnableTravel?: boolean;
    NotesRating?: string;
    TimeChangeRequestStatus?: string;
    Notify?: boolean;
    ShiftType?: string;
    CreatedDate?: Date;
    CreatedBy?: string;
    CreatedByUID?: string;
    ModifiedDate?: Date;
    ModifiedBy?: string;
    ModifiedByUID?: string;
    RecordOwner?: string;
    RecordOwnerUID?: string;
    LastActivityDate?: Date;
    IsRequired?: boolean;
}

export class ServiceTransactionRow extends IServiceTransactionRow {
    IsPassive?: boolean;
    ShowEmployeeCareTeam?: string;
    ShowEmployeeCareTeamOnlyAvailable?: boolean;

    getClassName?() {
        return RAFEntityName.ServiceTransaction;
    }
    getIdField?(): string {
        return propertyOf<ServiceTransactionRow>("UID");
    }
    getListUrl?(): string {
        return "ServiceTransaction/List";
    }
    getSaveUrl?(): string {
        return "ServiceTransaction/Save";
    }
    getRelatedListUrl?(): string {//use route name(entity displayname)
        return "ServiceTransaction/RelatedList";
    }

}
