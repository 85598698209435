import moment from "moment";
import { isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { RAFShiftActionStatus } from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import {
  DateRangeTypes,
  ScheduleFilterRow,
  ServiceTransactionNotesRating,
  ServiceTransactionStates,
  getServiceTransactionItemCountByStatus
} from "../ServiceTransactionHelper";

const today: Date = new Date(new Date().toDateString());
const last30Days = moment(today).subtract(30, "days").toDate();
const next30Days = moment(today).add(30, "days").toDate();
const yesterdayDate = moment(today).subtract(1, "days").toDate();
const tomorrowDate = moment(today).add(1, "days").toDate();

export class ShiftSummaryDetailsArray {
  title: string;
  count: number;
  body?: string;
  iconCss: string;
  themeName: string;
  status: RAFShiftActionStatus[];
  additionalShiftFilter?: ScheduleFilterRow;
}

export const getDefaultShiftSummaryDetailsArray = () => {
  const defaultShiftSummaryDetailsArray: ShiftSummaryDetailsArray[] = [
    {
      title: "Completed",
      count: 0,
      body: "Last 30 days",
      iconCss: "fas fa-square-check",
      themeName: "primary",
      status: [RAFShiftActionStatus.Completed],
      additionalShiftFilter: {
        RelatedToType: RAFEntityName.Employee,
        Status: RAFShiftActionStatus.Completed,
        DateRange: DateRangeTypes.Custom,
        SelectedDate: yesterdayDate,
        StartDate: last30Days,
        EndDate: yesterdayDate,
        State: ServiceTransactionStates.All,
        NotesRating: ServiceTransactionNotesRating.All
      },
    },
    {
      title: "Upcoming",
      count: 0,
      body: "Next 30 days",
      iconCss: "fas fa-forward",
      themeName: "secondary",
      status: [RAFShiftActionStatus.Scheduled],
      additionalShiftFilter: {
        RelatedToType: RAFEntityName.Employee,
        Status: RAFShiftActionStatus.Scheduled,
        DateRange: DateRangeTypes.Custom,
        SelectedDate: tomorrowDate,
        StartDate: tomorrowDate,
        EndDate: next30Days,
        State: ServiceTransactionStates.All,
        NotesRating: ServiceTransactionNotesRating.All
      },
    },
    {
      title: "Open Shift",
      count: 0,
      iconCss: "fas fa-folder-open",
      themeName: "tertiary",
      status: [RAFShiftActionStatus.Unassigned],
      additionalShiftFilter: {
        RelatedToType: RAFEntityName.Employee,
        Status: RAFShiftActionStatus.Unassigned,
        DateRange: DateRangeTypes.Custom,
        SelectedDate: today,
        StartDate: last30Days,
        EndDate: next30Days,
        State: ServiceTransactionStates.All,
        NotesRating: ServiceTransactionNotesRating.All
      },
    },
    {
      title: "Inprogress",
      count: 0,
      iconCss: "fas fa-arrows-rotate",
      themeName: "quaternary",
      status: [RAFShiftActionStatus.InProgress],
      additionalShiftFilter: {
        RelatedToType: RAFEntityName.Employee,
        Status: RAFShiftActionStatus.InProgress,
        DateRange: DateRangeTypes.Custom,
        SelectedDate: today,
        StartDate: last30Days,
        EndDate: next30Days,
        State: ServiceTransactionStates.All,
        NotesRating: ServiceTransactionNotesRating.All
      },
    },
  ];

  return defaultShiftSummaryDetailsArray;
};

export const getShiftSummaryDetailsArray = (
  relatedToUID: string,
) => {
  return new Promise<ShiftSummaryDetailsArray[]>(async (resolve) => {
    const shiftSummaryDetailsArray = getDefaultShiftSummaryDetailsArray();

    const promises = shiftSummaryDetailsArray.map(shiftSummaryDetails =>
      getServiceTransactionItemCountByStatus(
        relatedToUID,
        shiftSummaryDetails.additionalShiftFilter,
      )
    );

    const allRelatedServiceTransactions = await Promise.all(promises);

    for (let i = 0; i < shiftSummaryDetailsArray.length; i++) {
      shiftSummaryDetailsArray[i].count = isNotNullAndUndefined(allRelatedServiceTransactions[i])
        ? allRelatedServiceTransactions[i]
        : 0;
    }

    resolve(shiftSummaryDetailsArray);
  });
};
