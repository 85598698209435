import DOMPurify from "dompurify";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer
} from "react";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFEmptyState from "../../../../RAFComponents/Navigation/RAFEmptyState";
import RAFRecordInfo from "../../../../RAFComponents/Navigation/RAFRecordInfo";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  subscribeRAFEvent,
  unsubscribeRAFEvent,
} from "../../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import { CareRecipientPermissionConstants } from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  BrowserIsDevice,
  RAFButtonConstant,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { getAllActivityItems } from "../../../ActiveContacts/Activity/ActivityHelper";
import {
  ActivityRow,
  RAFActivityCommentType,
} from "../../../ActiveContacts/Activity/ActivityRow";
import "./../ServiceTransactionStyle.scss"

interface IProps {
  relatedToType?: string;
  careRecipientUID?: string;
  careRecipientName?: string;

  viewMode?: "Horizontal" | "Vertical";

  paddingClassname?: string;
}

interface IState {
  isLoading: boolean;
  activityItems: ActivityRow[];
  pinnedNotes: ActivityRow[];
  selectedObjectUID: string;
  showMoreClientNoteContent: boolean;
}

function ServiceTransactionNotes({
  careRecipientUID,
  careRecipientName,
  paddingClassname = "p-2 p-md-3",
  ...props
}: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      activityItems: null,
      pinnedNotes: null,
      showMoreClientNoteContent: false,
      selectedObjectUID: null,
    }
  );

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  useEffect(() => {
    refresh();
  }, [careRecipientUID]);

  useEffect(() => {
    const eventKey = `${RAFEventName.ReloadPinnedActivityContent}_${RAFEntityName.Activity
      }_${"PinnedNotesOnly"}_${careRecipientUID}`;
    subscribeRAFEvent(eventKey, refreshActivityList);

    return () => {
      unsubscribeRAFEvent(eventKey, refreshActivityList);
    };
  });

  const refreshActivityList = (args) => {
    if (isNotNullAndUndefined(args) && isNotNullAndUndefined(args.detail)) {
      let argscareRecipientUID = args.detail.careRecipientUID;
      if (argscareRecipientUID === careRecipientUID) {
        refresh();
      }
    }
  };

  const refresh = async () => {
    setState({
      isLoading: true,
    });

    if (isNotNullAndUndefined(careRecipientUID)) {
      const sortQuery: string[] = [
        propertyOf<ActivityRow>("Pinned"),
        propertyOf<ActivityRow>("DisplayOrder"),
        `${propertyOf<ActivityRow>("ModifiedDate")} desc`,
      ];

      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      let nullParentUIDFilter: RAFCustomFilter = {};
      nullParentUIDFilter.Operator = RAFCustomOperator.IsNull;
      nullParentUIDFilter.Field = propertyOf<ActivityRow>("ParentUID");
      customFilter.Rules.push(nullParentUIDFilter);

      let commentTypeFiler: RAFCustomFilter = {};
      let commentTypeFilterValue: string[] = [];
      commentTypeFilterValue.push(RAFActivityCommentType.Note);
      commentTypeFiler.Operator = RAFCustomOperator.Equal;
      commentTypeFiler.Value = commentTypeFilterValue;
      commentTypeFiler.Field = propertyOf<ActivityRow>("CommentType");
      customFilter.Rules.push(commentTypeFiler);

      const activityItems = await getAllActivityItems(
        null,
        careRecipientUID,
        null,
        null,
        customFilter,
        sortQuery,
        0,
        0
      );

      const pinnedNotes = isNotEmptyArray(activityItems)
        ? activityItems.filter((x) => x.Pinned)
        : [];

      setState({
        isLoading: false,
        activityItems,
        pinnedNotes,
      });
    } else {
      setState({
        isLoading: false,
        activityItems: [],
        pinnedNotes: [],
      });
    }
  };

  //show more ClientNote start
  const showMoreClientNoteContent = () => {
    if (state.showMoreClientNoteContent === true) {
      setState({ showMoreClientNoteContent: false });
    }
    else {
      setState({ showMoreClientNoteContent: true });
    }
  };

  //show more ClientNote end

  const headerTemplate = (item) => {
    return (
      <div
        className={`position-relative pointer`}
      >
        <div className={`align-items-center row g-3 flex-nowrap`}>
          <div className="col">
            <div className="d-flex align-items-center justify-content-between">
              <span className="subtitle_1 ecllipseFirstLine">
                {item.Subject}
              </span>

              {item.Pinned && (
                <span className="note_pin_outer_div">
                  <i className="note_pin_icon fas fa-thumbtack text_orange"></i>
                  {/* <i className="note_pin_icon fas fa-thumbtack" style={{ color: item.ColourCode }}></i> */}
                </span>
              )}
            </div>
            <div className="d-flex align-items-baseline body_3">
              <span className="body_3 pe-1">
                {" "}
                {item.Pinned ? "Pinned" : "Added"}
              </span>
              <span>
                {isNotNullAndUndefined(item.CreatedBy) ? (
                  <span className="d-flex align-items-center">
                    <span className="body_3 pe-1">by</span>
                    <span className="body_3 text-decoration-underline pe-1 ecllipseFirstLine word-break-all">
                      {item.CreatedBy}
                    </span>
                  </span>
                ) : (
                  ""
                )}
              </span>
              <div onClick={(e) => e.stopPropagation()}>
                <RAFRecordInfo
                  createdBy={item.CreatedBy}
                  createdDate={item.CreatedDate}
                  modifiedDate={item.ModifiedDate}
                  modifiedBy={item.ModifiedBy}
                  lastActivityDate={item.LastActivityDate}
                  content=""
                  hideClockIcon
                  dropdownBtnClassName="unset-custom-button-md p-0 remove-minWidth"
                  spanContentClassName="body_3"
                ></RAFRecordInfo>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const notesContent = (item) => {
    const colorCodeName = isNotNullAndUndefined(item.ColourCode)
      ? item.ColourCode
      : "#67C6C2";
    return (

      <div
        key={item.UID}
        className={
          props.viewMode === "Horizontal" ? "col-auto" : "col-12"
        }
        {...(BrowserIsDevice && props.viewMode === "Horizontal"
          ? { style: { width: "80%", minWidth: "300px" } }
          : {})}
      >
        <CustomCardWidget
          cardContentClassName={paddingClassname}
          style={{
            backgroundColor: colorCodeName,
            borderColor: colorCodeName,
          }}
        >
          {headerTemplate(item)}
          {IsNotNullOrWhiteSpace(item.Message) && (
            <div
              id={"summaryActivity_" + item.UID}
              // style={{ backgroundColor: item.ColorCode }}
              className="mt-2"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    item !== null
                      ? DOMPurify.sanitize(item.Message)
                      : undefined,
                }}
                className="remove_paragraph_margin body_2_dark description-text"
              ></span>
            </div>
          )}
        </CustomCardWidget>
      </div>
    );
  };

  const getNotesContentDiv = () => {
    const { pinnedNotes } = state;
    if (isNotEmptyArray(pinnedNotes)) {
      return (
        pinnedNotes.map((item, index) => {
            return (
              notesContent(item)
            );
        })
      );
    } else {
      return (
        <div className={"col-12"}>
          <RAFEmptyState title={"You do not have any pinned notes."} />
        </div>
      );
    }
  };

  const getBodyContent = () => {
    if (state.isLoading === false) {
      const { pinnedNotes } = state;
      if (isNotEmptyArray(pinnedNotes)) {
        return (
          <div className="p-md-1">
            <RAFPermissionRender
              permissionName={CareRecipientPermissionConstants.CareRecipientReadNotes}
            >
              <div className="service_transaction_note shadow-sm">
                <div className={`service_transaction_note_content${state.showMoreClientNoteContent ? ' active': ''}`}>
                  <div
                    className={`row align-items-center gy-3 g-0${props.viewMode === "Horizontal"
                      ? " gx-3 flex-nowrap"
                      : " flex-column"
                      }`}
                  >
                    {getNotesContentDiv()}
                  </div>
                </div>
                <div className={`service_transaction_note_footer${state.showMoreClientNoteContent ? ' active': ''}`}>
                      {/* <span className="service_transaction_note_footer_help_text">
                        <div className="raf_badge raf_xsm raf_badge_secondary">
                        {`${pinnedNotes.length} ${BrowserIsDevice ? 'pinned' :'pinned notes' }`}
                        </div>
                      </span> */}
                      <div className="">
                        <RAFButtonComponent className="custom-button-sm btn_brand_primary white"
                          action={state.showMoreClientNoteContent ? RAFButtonConstant.HideLess : RAFButtonConstant.ShowMore}
                          onClick={showMoreClientNoteContent}
                          iconPosition="right"
                          btnContent={`${pinnedNotes.length} pinned notes`}
                        ></RAFButtonComponent>
                  </div>
                </div>
              </div>
              {/* <div className="section__container">
                <div
                  className={`${props.viewMode === "Horizontal"
                    ? `${!BrowserIsDevice ? " customScrollBar" : ""
                    }overflow-auto`
                    : ""
                    }`}
                >
                  <CustomCardWidget cardContentClassName="p-2 p-md-3"
                    footerTemplate={
                      isNotNullAndUndefined(pinnedNotes) && pinnedNotes.length > 1 ?
                        <>
                          <div className="custom__card__separator custom__card__separator__light"></div>
                          <div className="p-2 px-3 d-flex justify-content-end">
                            <RAFButtonComponent className="custom-button-sm btn_brand_primary"
                              action={state.showMoreClientNoteContent ? RAFButtonConstant.HideLess : RAFButtonConstant.ShowMore}
                              onClick={showMoreClientNoteContent}
                              iconPosition="right"
                            ></RAFButtonComponent>
                          </div>
                        </>
                        :
                        ''
                    }
                  >
                    <div
                      className={`row align-items-center gy-3 g-0${props.viewMode === "Horizontal"
                        ? " gx-3 flex-nowrap"
                        : " flex-column"
                        }`}
                    >
                      {getNotesContentDiv()}
                    </div>
                  </CustomCardWidget>
                </div>
              </div> */}
            </RAFPermissionRender>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Loading..." />
        </div>
      );
    }
  };

  return (
    getBodyContent()
  );
}

export default React.memo(ServiceTransactionNotes);
