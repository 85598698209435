import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import DOMPurify from "dompurify";
import React, { PropsWithChildren, Reducer, useReducer, useRef } from "react";
import { RRule } from "rrule";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFRecordInfo from "../../../../RAFComponents/Navigation/RAFRecordInfo";
import { RafClearDialogContent } from "../../../../RAFComponents/helpers/AppHelper";
import { RRDays, RRFrequencyTypes } from "../../../../RAFComponents/helpers/RRuleInputHelper";
import {
  IsNotNullOrWhiteSpace,
  getUTCDateValue,
  isNotNullAndUndefined
} from "../../../../RAFComponents/helpers/utils";
import { MomentFormats, RAFButtonConstant } from "../../../../constants/Common/Constants";
import { getServiceTransactionDisplayNameFromStorage } from "../ServiceTransactionHelper";
import ManageServiceTransactionTemplate from "./ManageServiceTransactionTemplate";
import { ServiceTransactionTemplateRow } from "./ServiceTransactionTemplateRow";

interface IProps {
  serviceTransactionTemplateRow: ServiceTransactionTemplateRow;

  isChecked?: boolean;
  showCheckBox?: boolean;
  allowSelections?: boolean;
  showEditButton?: boolean;
  onChangeCheckBoxValue?: (isChecked: boolean) => void;

  onUpdateTemplate?: (entityId: string, objectName: string) => void;
  uiMode?: 'Card' | 'default';
}

interface IState {
  showManageTemplatDlg: boolean;
}

function ServiceTransactionTemplateCardContent({
  showCheckBox = false,
  showEditButton = true,
  serviceTransactionTemplateRow,
  ...props
}: PropsWithChildren<IProps>) {

  let manageTemplateDialogRef = useRef<DialogComponent>(null);
  const serviceTransactionDisplayName = getServiceTransactionDisplayNameFromStorage();
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showManageTemplatDlg: false,
    }
  );

  //update template dlg start
  const showManageTemplatDlg = () => {
    if (showEditButton === true) {
      setState({ showManageTemplatDlg: true });
    }
  };

  const manageTemplateContent = () => {
    if (state.showManageTemplatDlg) {
      return (
        <ManageServiceTransactionTemplate
          onSave={onUpdateTemplate.bind(this)}
          onClose={closeManageTemplateDlg.bind(this)}
          selectedServiceTransactionRow={serviceTransactionTemplateRow}
          isActive
        />
      );
    } else {
      return null;
    }
  };

  const onUpdateTemplate = async (entityId: string, objectName: string) => {
    await RafClearDialogContent(manageTemplateDialogRef);
    setState({ showManageTemplatDlg: false });
    setTimeout(() => {
      if (props.onUpdateTemplate) {
        props.onUpdateTemplate(entityId, objectName);
      }
    }, 10);
  };

  const closeManageTemplateDlg = async () => {
    await RafClearDialogContent(manageTemplateDialogRef);
    setState({ showManageTemplatDlg: false });
  };
  //update template dlg end 
  if (isNotNullAndUndefined(serviceTransactionTemplateRow)) {
    const rruleExpression =
      isNotNullAndUndefined(serviceTransactionTemplateRow) &&
        isNotNullAndUndefined(serviceTransactionTemplateRow.RRuleExpression)
        ? serviceTransactionTemplateRow.RRuleExpression
        : null;

    const ruleString = isNotNullAndUndefined(rruleExpression)
      ? RRule.fromString(rruleExpression)
      : null;

    const ruleOptions = isNotNullAndUndefined(ruleString)
      ? ruleString.origOptions
      : null;

    const ruleByWeekday = isNotNullAndUndefined(ruleOptions)
      ? ruleOptions.byweekday
      : null;

    const ruleByFrequency = isNotNullAndUndefined(ruleOptions)
      ? ruleOptions.freq
      : null;

    const frequencyText = isNotNullAndUndefined(ruleByFrequency)
      ? RRFrequencyTypes.find((item) => item.value === ruleByFrequency)?.text
      : null;

    const frequencyByWeekdayText = isNotNullAndUndefined(ruleByWeekday)
      ? Array.isArray(ruleByWeekday)
        ? RRDays.filter((item) => ruleByWeekday.includes(item.value))
        : RRDays.find((item) => item.value === ruleByWeekday)?.text
      : null;

    const onChangeCheckBoxValue = (isChecked: boolean) => {
      if (props.onChangeCheckBoxValue) {
        props.onChangeCheckBoxValue(isChecked);
      }
    };

    const customButton = (
      <>
        <div className="hidden">
          <RAFButtonComponent
            action={RAFButtonConstant.Edit}
            className="primary-custom-button custom-button-sm d-none d-md-block"
            onClick={showManageTemplatDlg}
          ></RAFButtonComponent>
          <RAFButtonComponent
            action={RAFButtonConstant.Edit}
            className="primary-custom-button custom-button-sm d-md-none"
            onClick={showManageTemplatDlg}
            iconBtn
          ></RAFButtonComponent>
        </div>
        <div>
          {state.showManageTemplatDlg === true && (
            <DialogComponent
              header={`Update ${serviceTransactionDisplayName} Template`}
              visible={state.showManageTemplatDlg}
              cssClass="rightDialog createEditForm full-height dlg-new-style"
              id="manage_ServiceTransactionTemplate_dlg"
              content={manageTemplateContent.bind(this)}
              isModal
              target="body"
              closeOnEscape={false}
              close={closeManageTemplateDlg.bind(this)}
              open={PreventFocusOnDialogOpen}
              showCloseIcon
              zIndex={1200}
              ref={manageTemplateDialogRef}
            ></DialogComponent>
          )}
        </div>
      </>
    );
    let startDate;
    let endDate;
    if (isNotNullAndUndefined(serviceTransactionTemplateRow.StartDate)) {
      startDate = getUTCDateValue(serviceTransactionTemplateRow.StartDate, MomentFormats.DATE);
    }
    if (isNotNullAndUndefined(serviceTransactionTemplateRow.EndDate)) {
      endDate = getUTCDateValue(serviceTransactionTemplateRow.EndDate, MomentFormats.DATE);
    }
    let startEndDateContent;
    if (
      isNotNullAndUndefined(startDate)) {
      startEndDateContent = startDate;
      if (IsNotNullOrWhiteSpace(endDate) && startDate !== endDate) {
        startEndDateContent = `${startEndDateContent} - ${endDate}`;
      }
    }

    const customTitle = (
      <div className="row gy-1 gx-0"
        onClick={() => {
          if (props.allowSelections) {
            onChangeCheckBoxValue(!props.isChecked);
          }
        }}
      >
        <div className="col-12 d-flex align-items-center justify-content-between"
          onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}>
          <div
            // className={showEditButton === true ? "grey-link-button with-underline" : ''}
            onClick={showManageTemplatDlg}
          >
            <span className={`secondary-header-bold-text${showEditButton === true ? " grey-link-button with-underline" : ''}`}>
              {serviceTransactionTemplateRow.Title}
            </span>
            {showEditButton === true &&
              <RAFButtonComponent action={RAFButtonConstant.Edit} iconCss={`${RAFButtonConstant.Edit.IconCss} fa-sm`} className="ms-2 link-button unset-custom-button-md line_height_normal"></RAFButtonComponent>
            }
          </div>
          {showEditButton === true && (
            <div>{customButton}</div>
          )}
        </div>
        {IsNotNullOrWhiteSpace(serviceTransactionTemplateRow.Assignee) && (
          <div className="col-12">
            <RAFDetailFieldCustom
              title="Assignee"
              value={serviceTransactionTemplateRow.Assignee}
              labelIconName="fas fa-sm fa-user"
              labelDivClassName="col-auto"
              valueDivClassName="col"
              valueClassName="details-value-sm-light"
              rowClassName="gx-1 align-items-baseline"
            />
          </div>
        )}

        {IsNotNullOrWhiteSpace(startDate) &&
          IsNotNullOrWhiteSpace(endDate) && (
            <div className="col-12">
              <RAFDetailFieldCustom
                title="StartDate"
                value={startEndDateContent}
                labelIconName="fas fa-sm fa-calendar-days"
                labelDivClassName="col-auto"
                valueDivClassName="col"
                valueClassName="details-value-sm-light"
                rowClassName="gx-1 align-items-baseline"
              />
            </div>
          )}
        <div className="col-12">
          <div className="detailsLeftCard-second-title h-auto">
            <div className="w-100">
              <div className="row gx-2 gy-1 align-items-center">
                {isNotNullAndUndefined(
                  frequencyText
                ) && (
                    <div className="col-auto detailsLeftCard-second-title-col">
                      <div className="detailsLeftCard-second-title-item">
                        <span
                          className={`e-badge e-badge-border`}
                          style={{ minWidth: "25px" }}
                        >
                          {frequencyText}
                        </span>
                      </div>
                    </div>
                  )}
                {isNotNullAndUndefined(frequencyByWeekdayText) && (
                  <div className="col-auto detailsLeftCard-second-title-col">
                    <div className="detailsLeftCard-second-title-item">
                      {Array.isArray(frequencyByWeekdayText) &&
                        frequencyByWeekdayText?.map(
                          (item: any, index: any) => {
                            return (
                              <span
                                className={`${index === 0 ? "" : "ms-1 "
                                  }e-badge e-badge-border`}
                                style={{ minWidth: "25px" }}
                                key={item.text}
                              >
                                {item.text.charAt(0)}
                              </span>
                            );
                          }
                        )}
                    </div>
                  </div>
                )}
                {isNotNullAndUndefined(serviceTransactionTemplateRow.CreatedBy) && (
                  <div className="col-auto detailsLeftCard-second-title-col">
                    <div className="detailsLeftCard-second-title-item">
                      <RAFRecordInfo
                        createdBy={serviceTransactionTemplateRow.CreatedBy}
                        createdDate={serviceTransactionTemplateRow.CreatedDate}
                        modifiedDate={serviceTransactionTemplateRow.ModifiedDate}
                        modifiedBy={serviceTransactionTemplateRow.ModifiedBy}
                        lastActivityDate={
                          serviceTransactionTemplateRow.LastActivityDate
                        }
                        hideClockIcon
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {isNotNullAndUndefined(serviceTransactionTemplateRow.Description) && (
          <div className="col-12">
            <div className="secondary-header-text-dark p-2 bg-grey">
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    serviceTransactionTemplateRow !== null
                      ? DOMPurify.sanitize(serviceTransactionTemplateRow.Description)
                      : undefined,
                }}
              ></span>
            </div>
          </div>
        )}
      </div>
    );

    const customCard = (
      <div className="px-3 py-2">
        <div className="row gx-3 gy-1">
          {showCheckBox && (
            <div
              className="col-auto"
              key={props.isChecked ? props.isChecked.toString() : ""}
            >
              <div className="mt-2">
                <CheckBoxComponent
                  name={serviceTransactionTemplateRow.UID}
                  value={serviceTransactionTemplateRow.UID}
                  // cssClass="e-checkbox-light e-checkbox-lg raf-round show-check-icon"
                  cssClass="e-checkbox-light"
                  change={(e) => onChangeCheckBoxValue(e.checked)}
                  checked={props.isChecked ?? false}
                />
              </div>
            </div>
          )}
          <div className="col">{customTitle}</div>
          {/* {isNotNullAndUndefined(serviceTransactionTemplateRow.Description) &&
            IsNotNullOrWhiteSpace(serviceTransactionTemplateRow.Description) && (
              <div className="col-12">
                <span className="secondary-header-text">
                  {serviceTransactionTemplateRow.Description}
                </span>
              </div>
            )} */}
        </div>
      </div>
    );

    return (
      <div
        key={serviceTransactionTemplateRow.UID}
        style={{ cursor: "pointer" }}
      >
        {showCheckBox ? (
          <CustomCardWidget
            removeContentPadding
          >
            {customCard}
          </CustomCardWidget>
        ) :
          props.uiMode === 'Card' ? (
            <CustomCardWidget
              cardContentClassName={`py-2 ${props.allowSelections && props.isChecked ? 'bg-light-blue' : ''}`}
            >
              {customTitle}
            </CustomCardWidget>
          ) : (
            <div>
              {customTitle}
            </div>
          )}
      </div>
    );
  } else {
    return null;
  }
}

export default React.memo(ServiceTransactionTemplateCardContent);