import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import RAFDeletedRecordState from '../../../../RAFComponents/Navigation/RAFDeletedRecordState';
import RAFAttributeRelatedListProvider from '../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider';
import { IsNotNullOrWhiteSpace, IsNullOrWhiteSpace, isNotNullAndUndefined } from '../../../../RAFComponents/helpers/utils';
import { CareEsioEntity } from '../../../../constants/CareESIO/CareEsioConstants';
import { Constants } from '../../../../constants/Common/Constants';
import AboutClient from '../../../CareESIO/CareRecipient/Details/AboutClient';
import CareRecipientProfileInformation from '../../../CareESIO/CareRecipient/Details/CareRecipientProfileInformation';
import { atomSelectedRecipientRightContent } from '../../../CareESIO/CareRecipientHelper';
import RAFEntityProvider from '../../../Common/Providers/RAFEntityProvider';
import RAFObjectContextProvider, { RAFObjectContext } from '../../../Common/Providers/RAFObjectContextProvider';

interface IProps {
    careRecipientUID: string;
    careRecipientModule: string;
    displayType: 'About' | 'ProfileInfoHeader';
    careRecipientRow: any;
}

const ServiceTransactionProfileInfoHeader: React.FC<IProps> = ({ displayType, careRecipientModule, careRecipientUID, careRecipientRow }) => {

    const selectedRecipientRightContent = useRecoilValue(atomSelectedRecipientRightContent(careRecipientModule));

    const getClientProfileInfo = (rafObject) => {
        return (
            <div className="h-100 bg-white">
                <div className={`section__container`}>
                    <div className={`section__div customScrollBar`}>
                        <CareRecipientProfileInformation
                            careRecipientUID={careRecipientUID}
                            selectedTabItem={{
                                text: "Overview",
                                value: "CareInfo",
                            }}
                            isdashboard={true}
                            recipientObject={rafObject}
                        />
                    </div>
                    {/* <div className={BrowserIsDevice ? "pt-3" : "p-3"}>
                        <div className="d-flex align-items-center">
                            <RAFButtonComponent btnContent={`View ${rafObject['first_name']}'s details`} isPrimary className="w-100 me-2"
                                onClick={() => setSelectedRecipientRightContent(careRecipientModule)}
                            ></RAFButtonComponent>
                            <RAFButtonComponent btnContent={`Go to ${rafObject['first_name']}'s profile`} isPrimary className="w-100"
                                onClick={() => navigate(`/RAF/View/care_recipient/${careRecipientUID}`)}
                            ></RAFButtonComponent>
                        </div>
                    </div> */}
                </div>

            </div>
        );
    };

    const getAboutClient = () => {
        return (
            <AboutClient selectedTab={selectedRecipientRightContent} />
        );
    };

    return (
        <RAFEntityProvider moduleName={careRecipientModule} key={careRecipientUID}>
            <RAFAttributeRelatedListProvider moduleName={careRecipientModule}>
                <RAFObjectContextProvider
                    moduleName={careRecipientModule}
                    objectData={careRecipientRow}
                    objectId={careRecipientUID}
                >
                    <RAFObjectContext.Consumer>
                        {({ rafObject, error }) => {
                            if (
                                isNotNullAndUndefined(rafObject) &&
                                IsNotNullOrWhiteSpace(rafObject.UID)
                            ) {
                                if (displayType === 'About') {
                                    return getAboutClient();
                                } else {
                                    return (
                                        getClientProfileInfo(rafObject)
                                    );
                                }
                            } else {
                                if (IsNullOrWhiteSpace(careRecipientUID) || error === Constants.RAFObject_NoContent) {
                                    return (
                                        <div className="container-fluid px-0">
                                            <RAFDeletedRecordState
                                                title={`You cannot view information about this ${CareEsioEntity.CareRecipient.DisplayName} because the record has been deleted or you do not have sufficient privileges.`}
                                                showBackBtn={false}
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            className="container-fluid px-0"
                                            style={{ background: "transparent" }}
                                        ></div>
                                    );
                                }
                            }
                        }}
                    </RAFObjectContext.Consumer>
                </RAFObjectContextProvider>
            </RAFAttributeRelatedListProvider>
        </RAFEntityProvider>
    );
};

export default ServiceTransactionProfileInfoHeader;