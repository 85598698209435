import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer
} from "react";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import { isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { BrowserIsDevice } from "../../../../constants/Common/Constants";
import ServiceTimesheetSummaryGridContent from "./ServiceTimesheetSummaryGridContent";
import { TimeSheetSummaryDetailsArray, getDefaultTimeSheetSummaryDetailsArray, getTimeSheetSummaryDetailsArray } from "./ServiceTimesheetSummaryHelper";

interface IProps {
  careRecipientUID: string;
  careRecipientName: string;
}

interface IState {
  selectedItem: TimeSheetSummaryDetailsArray;
  showTimeSheetSummaryDetails: boolean;
  timeSheetSummaryDetailsArray: TimeSheetSummaryDetailsArray[];
}

function ServiceTimesheetSummary({
  careRecipientUID,
}: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      selectedItem: null,
      showTimeSheetSummaryDetails: false,
      timeSheetSummaryDetailsArray: getDefaultTimeSheetSummaryDetailsArray(careRecipientUID),

    }
  );

  useEffect(() => {
    refresh();
  }, [careRecipientUID]);

  const refresh = async () => {
    setState({ showTimeSheetSummaryDetails: false });

    const timeSheetSummaryDetailsArray = await getTimeSheetSummaryDetailsArray(careRecipientUID);

    setState({
      timeSheetSummaryDetailsArray
    });
  };

  //timesheet summary details starts
  const showTimeSheetSummaryDetailsContent = (selectedItem: TimeSheetSummaryDetailsArray) => {
    setState({
      showTimeSheetSummaryDetails: true,
      selectedItem
    });
  };

  const timeSheetSummaryDetailsDialogContent = () => {
    if (state.showTimeSheetSummaryDetails) {
      return (
        <div className="e-dlg-content-outer">
          <div className="e-dlg-body-content">
            <ServiceTimesheetSummaryGridContent
              selectedTimeSheetSummaryItem={state.selectedItem}
              isActive
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const closeTimeSheetSummaryDetailsContent = () => {
    setState({
      showTimeSheetSummaryDetails: false,
      selectedItem: null
    });
  };

  //timesheet summary details ends

  return (
    <div className={BrowserIsDevice ? "overflow-auto" : ''} style={{ cursor: "pointer" }}>
      <div className="row g-3 flex-nowrap">
        {state.timeSheetSummaryDetailsArray.map((item, index) => {
          return (
            <div className={BrowserIsDevice ? 'col' : "col-4"} key={index}
              {...BrowserIsDevice && { style: { minWidth: '200px' } }}
            >
              <CustomCardWidget
                onClick={() => showTimeSheetSummaryDetailsContent(item)}
                cardClassName={`overflow-hidden custom__card__leftBorder custom__card__leftBorder__lg ${item.themeName}`}
                cardContentClassName="p-2-5"
              >
                <div className="subtitle_1 extra_bold">{item.count}</div>
                <span className="body_2">{item.title}</span>
              </CustomCardWidget>
            </div>
          );
        })}
      </div>
      {state.showTimeSheetSummaryDetails && (
        <DialogComponent
          header={isNotNullAndUndefined(state.selectedItem) ? state.selectedItem.title : "TIMESHEET & INVOICE"}
          showCloseIcon
          visible={state.showTimeSheetSummaryDetails}
          cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog dlg-new-style"
          id="care_details_dialog"
          content={timeSheetSummaryDetailsDialogContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={closeTimeSheetSummaryDetailsContent.bind(this)}
          open={PreventFocusOnDialogOpen}
          zIndex={1200}
        />
      )}
    </div>
  );
}
export default React.memo(ServiceTimesheetSummary);
